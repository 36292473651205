var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "placeList" }, [
    _c("div", { staticClass: "selectItem", attrs: { "bi-route": "bang" } }, [
      _vm._m(0),
      _c("h5", { staticClass: "selectSubTitle" }, [_vm._v("汇总当下热销爆品")]),
      _c("div", { staticClass: "selectItemTabs" }, [
        _c(
          "div",
          _vm._l(_vm.hotClassIfyList, function(item, index) {
            return _c(
              "span",
              {
                key: item.cid,
                class: index == _vm.hotIndex ? "active" : "",
                attrs: { "bi-click": "fenlei" },
                on: {
                  mouseover: function(e) {
                    return _vm.handleChangeHot(item, index, "hot", e)
                  }
                }
              },
              [_vm._v(_vm._s(item.cname))]
            )
          }),
          0
        )
      ]),
      _c(
        "div",
        {
          staticClass: "hotListProduct",
          style: { width: _vm.hList.length * 393 + "px" },
          attrs: { id: "hWrap" }
        },
        _vm._l(_vm.hList, function(outerItem, i) {
          return _c("div", { key: i + "hWrap", staticClass: "outerWrap" }, [
            _c(
              "div",
              {
                staticClass: "swiper-container",
                attrs: { id: "swiperHot" + i }
              },
              [
                _c(
                  "div",
                  { staticClass: "swiper-wrapper" },
                  _vm._l(
                    new Array(Math.ceil(outerItem.length / 3)).fill(""),
                    function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index + _vm.hotIndex,
                          staticClass: "swiper-slide"
                        },
                        _vm._l(
                          outerItem.slice(index * 3, (index + 1) * 3),
                          function(citem, cindex) {
                            return _c(
                              "div",
                              {
                                key: citem.id,
                                staticClass: "swiperItem",
                                attrs: {
                                  "bi-click": "goods",
                                  "data-index": cindex,
                                  "data-pid": _vm.currHotCid,
                                  "data-gid": citem.id || citem.goodsId
                                }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      href:
                                        "?r=/l/d&nav_wrap=p&id=" +
                                        (citem.id || citem.goodsId)
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "marketgroup",
                                            rawName: "v-marketgroup",
                                            value: citem,
                                            expression: "citem"
                                          }
                                        ],
                                        staticClass: "cardLeft",
                                        attrs: { size: "size" }
                                      },
                                      [
                                        _c("label", [
                                          _vm._v(_vm._s(citem.ranking))
                                        ]),
                                        _c("img", {
                                          attrs: {
                                            alt: "",
                                            src: citem.mainPic + "_310x310.jpg"
                                          }
                                        })
                                      ]
                                    ),
                                    _c("div", { staticClass: "cardRight" }, [
                                      _c("h5", { staticClass: "cardTit" }, [
                                        citem.tchaoshi === 1 ||
                                        citem.shopType === 1
                                          ? _c("i", {
                                              class: [
                                                citem.tchaoshi
                                                  ? "maoc"
                                                  : "tmail",
                                                "tagTit"
                                              ],
                                              attrs: {
                                                title: citem.tchaoshi
                                                  ? "天猫超市"
                                                  : "天猫"
                                              }
                                            })
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "text-ellipsis",
                                            attrs: {
                                              title: citem.dtitle || citem.title
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                citem.dtitle || citem.title
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "selectFeature" },
                                        [
                                          citem.activityType === 2
                                            ? _c("span", [_vm._v("聚划算")])
                                            : _vm._e(),
                                          _c("span", [
                                            _vm._v(
                                              "券" +
                                                _vm._s(citem.couponPrice) +
                                                "元"
                                            )
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "selectPrice" },
                                        [
                                          _c("span", [
                                            _c("i", [_vm._v("￥")]),
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("salesNum")(
                                                  citem.actualPrice
                                                )
                                              ) + " "
                                            )
                                          ]),
                                          _c("u", [
                                            _vm._v(
                                              "￥" +
                                                _vm._s(
                                                  _vm._f("salesNum")(
                                                    citem.originalPrice
                                                  )
                                                )
                                            )
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "selectSales" },
                                        [
                                          _vm._v(" 近2小时热度值 "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("salesNum")(
                                                  citem.twoHoursSales
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          }
                        ),
                        0
                      )
                    }
                  ),
                  0
                ),
                _c("div", {
                  class: ["swiper-button-prev"],
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                }),
                _c("div", {
                  class: ["swiper-button-next"],
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                }),
                _c("div", { class: ["splacePagination"] })
              ]
            )
          ])
        }),
        0
      )
    ]),
    _c("div", { staticClass: "selectItem", attrs: { "bi-route": "9kuai9" } }, [
      _vm._m(1),
      _c("h5", { staticClass: "selectSubTitle" }, [_vm._v("精选好货1.9起")]),
      _c("div", { staticClass: "selectItemTabs" }, [
        _c(
          "div",
          _vm._l(_vm.linkList, function(item, index) {
            return _c(
              "span",
              {
                key: item.lable,
                class: index == _vm.nineIndex ? "active" : "",
                attrs: { "bi-click": "fenlei" },
                on: {
                  mouseover: function(e) {
                    return _vm.handleChangeHot(item, index, "nine", e)
                  }
                }
              },
              [_vm._v(_vm._s(item.lable))]
            )
          }),
          0
        )
      ]),
      _c(
        "div",
        {
          staticClass: "hotListProduct",
          style: { width: _vm.nList.length * 393 + "px" },
          attrs: { id: "nWrap" }
        },
        _vm._l(_vm.nList, function(outerItem, index) {
          return _c("div", { key: index + "nWrap", staticClass: "outerWrap" }, [
            _c(
              "div",
              {
                staticClass: "swiper-container",
                attrs: { id: "swiperNine" + index }
              },
              [
                _c(
                  "div",
                  { staticClass: "swiper-wrapper" },
                  _vm._l(
                    new Array(Math.ceil(outerItem.length / 3)).fill(""),
                    function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index + _vm.hotIndex,
                          staticClass: "swiper-slide"
                        },
                        _vm._l(
                          outerItem.slice(index * 3, (index + 1) * 3),
                          function(citem, cindex) {
                            return _c(
                              "div",
                              {
                                key: citem.id,
                                staticClass: "swiperItem",
                                attrs: {
                                  "bi-click": "goods",
                                  "data-index": cindex,
                                  "data-pid": _vm.currNineCid,
                                  "data-gid": citem.id || citem.goodsId
                                }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      href:
                                        "?r=/l/d&nav_wrap=nine&id=" +
                                        (citem.id || citem.goodsId)
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "marketgroup",
                                            rawName: "v-marketgroup",
                                            value: citem,
                                            expression: "citem"
                                          }
                                        ],
                                        staticClass: "cardLeft",
                                        attrs: { size: "size" }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            alt: "",
                                            src: citem.mainPic + "_310x310.jpg"
                                          }
                                        })
                                      ]
                                    ),
                                    _c("div", { staticClass: "cardRight" }, [
                                      _c("h5", { staticClass: "cardTit" }, [
                                        citem.tchaoshi === 1 ||
                                        citem.shopType === 1
                                          ? _c("i", {
                                              class: [
                                                citem.tchaoshi
                                                  ? "maoc"
                                                  : "tmail",
                                                "tagTit"
                                              ],
                                              attrs: {
                                                title: citem.tchaoshi
                                                  ? "天猫超市"
                                                  : "天猫"
                                              }
                                            })
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "text-ellipsis",
                                            attrs: {
                                              title: citem.dtitle || citem.title
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                citem.dtitle || citem.title
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "selectFeature" },
                                        [
                                          citem.activityType === 2
                                            ? _c("span", [_vm._v("聚划算")])
                                            : _vm._e(),
                                          _c("span", [
                                            _vm._v(
                                              "券" +
                                                _vm._s(citem.couponPrice) +
                                                "元"
                                            )
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "selectPrice" },
                                        [
                                          _c("span", [
                                            _c("i", [_vm._v("￥")]),
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("salesNum")(
                                                  citem.actualPrice
                                                )
                                              ) + " "
                                            )
                                          ]),
                                          _c("u", [
                                            _vm._v(
                                              "￥" +
                                                _vm._s(
                                                  _vm._f("salesNum")(
                                                    citem.originalPrice
                                                  )
                                                )
                                            )
                                          ])
                                        ]
                                      ),
                                      _c("div", { staticClass: "selectHot" }, [
                                        _vm._v(" 爆卖 "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("salesNum")(
                                                citem.monthSales,
                                                _vm._,
                                                1
                                              )
                                            )
                                          )
                                        ]),
                                        _vm._v(" 件 ")
                                      ])
                                    ])
                                  ]
                                )
                              ]
                            )
                          }
                        ),
                        0
                      )
                    }
                  ),
                  0
                ),
                _c("div", {
                  staticClass: "swiper-button-prev",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                }),
                _c("div", {
                  staticClass: "swiper-button-next",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                }),
                _c("div", { staticClass: "splacePagination" })
              ]
            )
          ])
        }),
        0
      )
    ]),
    _c("div", { staticClass: "selectItem", attrs: { "bi-route": "banjia" } }, [
      _vm._m(2),
      _c("h5", { staticClass: "selectSubTitle" }, [
        _vm._v("聚划算爆品，领券还能省")
      ]),
      _c("div", { staticClass: "selectItemTabs" }, [
        _c(
          "div",
          _vm._l(_vm.zszClassIfyList, function(item, index) {
            return _c(
              "span",
              {
                key: item.cids,
                class: index == _vm.zszIndex ? "active" : "",
                attrs: { "bi-click": "fenlei" },
                on: {
                  mouseover: function(e) {
                    return _vm.handleChangeHot(item, index, "zsz", e)
                  }
                }
              },
              [_vm._v(_vm._s(item.cname))]
            )
          }),
          0
        )
      ]),
      _c(
        "div",
        {
          staticClass: "hotListProduct",
          style: { width: _vm.hList.length * 393 + "px" },
          attrs: { id: "zWrap" }
        },
        _vm._l(_vm.zList, function(outerItem, i) {
          return _c("div", { key: i + "zWrap", staticClass: "outerWrap" }, [
            _c(
              "div",
              {
                staticClass: "swiper-container",
                attrs: { id: "swiperZsz" + i }
              },
              [
                _c(
                  "div",
                  { staticClass: "swiper-wrapper" },
                  _vm._l(
                    new Array(Math.ceil(outerItem.length / 3)).fill(""),
                    function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index + _vm.zszIndex,
                          staticClass: "swiper-slide"
                        },
                        _vm._l(
                          outerItem.slice(index * 3, (index + 1) * 3),
                          function(citem, cindex) {
                            return _c(
                              "div",
                              {
                                key: citem.id,
                                staticClass: "swiperItem",
                                attrs: { "bi-click": "goods" }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      href:
                                        "?r=/l/d&nav_wrap=p&id=" +
                                        (citem.id || citem.goodsId)
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "marketgroup",
                                            rawName: "v-marketgroup",
                                            value: citem,
                                            expression: "citem"
                                          }
                                        ],
                                        staticClass: "cardLeft",
                                        attrs: { size: "size" }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            alt: "",
                                            src: citem.mainPic + "_310x310.jpg"
                                          }
                                        })
                                      ]
                                    ),
                                    _c("div", { staticClass: "cardRight" }, [
                                      _c("h5", { staticClass: "cardTit" }, [
                                        citem.tchaoshi === 1 ||
                                        citem.shopType === 1
                                          ? _c("i", {
                                              class: [
                                                citem.tchaoshi
                                                  ? "maoc"
                                                  : "tmail",
                                                "tagTit"
                                              ],
                                              attrs: {
                                                title: citem.tchaoshi
                                                  ? "天猫超市"
                                                  : "天猫"
                                              }
                                            })
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "text-ellipsis",
                                            attrs: {
                                              title: citem.dtitle || citem.title
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                citem.dtitle || citem.title
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "selectFeature" },
                                        [
                                          citem.activityType === 2
                                            ? _c("span", [_vm._v("聚划算")])
                                            : _vm._e(),
                                          _c("span", [
                                            _vm._v(
                                              "券" +
                                                _vm._s(citem.couponPrice) +
                                                "元"
                                            )
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "selectFeature" },
                                        [
                                          citem.specialText &&
                                          citem.specialText[0]
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(citem.specialText[0])
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "selectPrice" },
                                        [
                                          _c("span", [
                                            _c("i", [_vm._v("￥")]),
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("salesNum")(
                                                  citem.actualPrice
                                                )
                                              ) + " "
                                            )
                                          ]),
                                          _c("u", [
                                            _vm._v(
                                              "￥" +
                                                _vm._s(
                                                  _vm._f("salesNum")(
                                                    citem.originalPrice
                                                  )
                                                )
                                            )
                                          ])
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          }
                        ),
                        0
                      )
                    }
                  ),
                  0
                ),
                _c("div", {
                  class: ["swiper-button-prev"],
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                }),
                _c("div", {
                  class: ["swiper-button-next"],
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                }),
                _c("div", { class: ["splacePagination"] })
              ]
            )
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "selectTitle",
        attrs: { href: "?r=p", target: "_blank", "bi-click": "gengduo" }
      },
      [
        _vm._v(" 热销榜 "),
        _c("img", {
          attrs: {
            src: require("./images/more@2x.png"),
            alt: "更多",
            height: "20",
            width: "20"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "selectTitle",
        attrs: { href: "?r=nine&u=1", target: "_blank", "bi-click": "gengduo" }
      },
      [
        _vm._v(" 9.9包邮 "),
        _c("img", {
          attrs: {
            src: require("./images/more@2x.png"),
            alt: "更多",
            height: "20",
            width: "20"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "selectTitle",
        attrs: {
          href: "?r=/realtime/zsz",
          target: "_blank",
          "bi-click": "gengduo"
        }
      },
      [
        _vm._v(" 折上折 "),
        _c("img", {
          attrs: {
            src: require("./images/more@2x.png"),
            alt: "更多",
            height: "20",
            width: "20"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
import Header from  './header/Header.vue';
import PlaceList from './placeList/index.vue'
import BrandSelection from './brandSelection/BrandSelection.vue'
import GoodsStuff from './goodsStuff/GoodsStuff.vue'
import AdsSpace from './adsSpace/AdsSpace.vue'

export {
    Header,
    PlaceList,
    BrandSelection,
    GoodsStuff,
    AdsSpace
}
<template>
  <div class="adsSpace_container" v-if="dyList.length || hwList.length">
    <!-- 抖音热销 -->
    <goods-space :config="dyConfig" v-if="dyList.length" type='tiktok' :data="dyList"/>
    <!-- 种草好物 -->
    <goods-space :config="hwConfig" v-if="hwList.length" type='recommend' :data="hwList"/>
  </div>
</template>

<script>
import GoodsSpace from "./widget/goodsSpace.vue";
export default {
  components: {
    GoodsSpace,
  },
  data() {
    return {
      dyList: [],
      hwList: [],
      dyConfig: {},
      hwConfig: {},
    };
  },
  mounted() {
    this.getListData();
  },
  methods: {
    getListData() {
      this.dyConfig = {
        background:
          "https://sr.ffquan.cn/cms_pic/20220418/c9ec99ngftt4eoa014pg0.png",
        icon: "https://sr.ffquan.cn/cms_pic/20220418/c9ecaj7gftt4eoa014q01.png",
      };
      this.hwConfig = {
        background:
          "https://sr.ffquan.cn/cms_pic/20220418/c9ec99ngftt4eoa014pg1.png",
        icon: "https://sr.ffquan.cn/cms_pic/20220418/c9ecaj7gftt4eoa014q00.png",
      };
      this.$services.getRecommendGoodsList().then((res) => {
        if (res && res.code != 1) return false;
        this.dyList = res.data.tiktokGoodsList;
        this.hwList = res.data.recommendGoodsList;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.adsSpace_container {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
}
</style>
<template>
  <div class="w-1200">
      <div class="containerHead">
          <div class="headLeft" bi-route="fenlei">
              <dl v-for="item in classfilyInfo" :key=item.id>
                  <dt class="tit"><img :src="item.img" alt="" class="ico">{{item.name}}</dt>
                  <dd >
                      <a bi-click="leimu" :data-leimu_pname="item.name" :data-leimu_name="citem.lable" v-for="(citem, index) in item.children" :key="index" :href="citem.src" target="_blank" :class="citem.color ? 'hot' : ''">{{citem.lable}}</a>
                  </dd>
              </dl>
          </div>
          <div v-show="loading" class="headCenter bg">
              <div class="loadImg">
                  <img :src="loadingImg" alt="">
              </div>
        </div>
          <div class="headCenter" bi-route="lunbo" v-show="!loading" >
              <div class="swiper">
                    <div class="swiper-container swiperHeader">
                        <div class="swiper-wrapper">
                            <div
                                class="swiper-slide"
                                v-for="(item,index) in absList.middle_banner"
                                :key="item.c_type + index"
                            >
                                <a bi-click="shang" :data-index="index" :data-title="item.title" v-jump="item" target="_blank">
                                    <img :src="item.img" alt="" height="300" width="670">
                                </a>
                            </div>
                        </div>
                        <!-- 如果需要导航按钮 -->
                        <div class="swiper-button-prev" @click.stop=""></div>
                        <div class="swiper-button-next" @click.stop=""></div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
                <div class="swiperDown">
                    <div v-for="(item, index) in absList.middle_bottom_banner" :key="index">
                        <a bi-click="xia" :data-title="item.title" :data-index="index" :href="item.url" target="_blank">
                            <img v-lazy="`${item.img}_310x310.jpg`" alt="">
                        </a>
                    </div>
                </div>
          </div>
          <div class="headRight" bi-route="bimai">
              <div>
                  <div class="text">
                      <span>今日必买</span>
                      <span bi-click="huanyipi" class="changeData" @click="handleChangeData">
                          <img src="../header/images/change_icon.png" alt="">换一批
                      </span>
                  </div>
                  <p>精选买送/历史低价/大牌清仓高性价比爆品</p>
              </div>
              <div class="prodWrap">
                  <div class="prodItem" v-for="(item, index) in showBuyList" bi-click="goods" :data-index="index" :data-gid="item.id" :key="item.id">
                      <a :href="`?r=l/d&id=${item.id}&u=1`" class="prodCard" target="_blank">
                          <div class="cardL">
                              <span>{{item.specialType == 2 ? '品牌折扣' : item.specialType == 3 ? '历史低价' : '限时买送' }}</span>
                              <img v-lazy="`${item.mainPic}_310x310.jpg`" alt="">
                          </div>
                          <div class="cardR">
                              <div class="row1">
                                  <i v-if="item.tchaoshi === 1 || item.shopType === 1" :class="[item.tchaoshi ? 'maoc' : 'tmail' ,'tagTit']" :title="item.tchaoshi ? '天猫超市' : '天猫'"></i>
                                  <span :title="item.dtitle || item.title">{{item.dtitle || item.title}}</span>
                              </div>
                              <div class="row2">
                                  <span v-if="item.specialType == 2 || item.specialText.length">
                                      <img src="../header/images/prod_icon4.png" alt="">
                                      <span>{{
                                          item.specialType == 2 ? item.brandName : item.specialType == 3 ? item.specialText[0] : item.specialText[0]
                                          }}</span>
                                  </span>
                              </div>
                              <div class="row3">
                                  <span>
                                      <span>￥</span>
                                      <span>{{item.actualPrice | salesNum}}</span>
                                  </span>
                                  <span>
                                      <span>￥</span>
                                      <span>{{item.originalPrice | salesNum}}</span>
                                  </span>
                              </div>
                          </div>
                      </a>
                  </div>
              </div>
          </div>
      </div>
      <!-- 全局公告 -->
      <div class="all_tip_container" v-if="absList.ad_notice_pc && absList.ad_notice_pc.length">
        <div class="all_tip" v-for="(item,index) in absList.ad_notice_pc" :key="item.c_type + index">
            <img src="./images/tip_icon.png" alt="">
            <a target="_blank" v-jump="item" v-if="item.url != '0'" class="isJump">{{item.img}}</a>
            <span class="isJump" v-else >{{item.img}}</span>
        </div>
      </div>
      <!-- ddq -->
      <div class="ddQ" bi-route="dongdong">
          <div class="ddqL">
              <a href="/?r=ddq&u=1" target="_blank" bi-click="gengduo">
                <div class="ddqLogo"></div>
                <p class="text1">严选好货限时秒杀</p>
              </a>
              <p class="text2">{{timeDesc}}:00场</p>
              <p class="text3">距离结束</p>
              <div class="ddqTime">
                  <span>{{hours}}</span>
                  <span>{{minutes}}</span>
                  <span>{{seconds}}</span>
              </div>
          </div>
          <div class="ddqR">
              <!-- <div v-show="ddqLoad" class="loadImg ddqLoad">
                  <img :src="loadingImg" alt="">
              </div> -->
              <div class="ddqCardWrap" v-show="!ddqLoad">
                  <div class="moveWrap">
                      <div class="swiper-wrapper">
                           <a
                           v-for="(item, index) in ddqList"
                           :key="item.id"
                            class="ddCard swiper-slide"
                            :href="`index.php?r=l/d&id=${item.id}&u=1`"
                            target="_blank"
                            bi-click="goods"
                            :data-index="index"
                            :data-gid="item.id"
                            :data-time="`${timeDesc}:00`"
                        >
                            <span class="lowPrice" v-if="item.specialText && item.specialText.length">{{item.specialText[0]}}</span>
                            <div v-marketgroup="item">
                                <img :src="item.mainPic + '_310x310.jpg'" alt="" class="mainImg">
                            </div>
                            <div class="row1">
                                <i v-if="item.tchaoshi === 1 || item.shopType === 1" :class="[item.tchaoshi ? 'maoc' : 'tmail' ,'tagTit']" :title="item.tchaoshi ? '天猫超市' : '天猫'"></i>
                                  <span :title="item.dtitle || item.title">{{item.dtitle || item.title}}</span>
                            </div>
                            <div class="row2">
                                <span style="display: flex;align-items: flex-end;" >
                                    <span class="price1">
                                        <span style="font-size: 14px;">￥</span>
                                        <span style="font-size: 21px;line-height: 24px;">{{item.actualPrice}}</span>
                                    </span>
                                    <span  class="price2">
                                        <span>￥</span>
                                        <span>{{item.originalPrice}}</span>
                                    </span>
                                </span>
                                <span class="tag" v-if="item.ddqDesc">
                                    {{item.ddqDesc}}
                                </span>
                            </div>
                            <div class="row3">
                                <div class="progress" :style="{width: computeNummber(item.couponReceiveNum, item.couponTotalNum)}"></div>
                                <span>
                                    <span>已抢</span>
                                    <span>{{item.monthSales | salesNum(_,1)}}</span>
                                    <span>件</span>
                                </span>
                            </div>
                        </a>
                      </div>
                       <!-- 如果需要导航按钮 -->
                    <div class="prev" @click.stop=""></div>
                    <div class="next" @click.stop=""></div>
                    </div>
                    
              </div>
          </div>
      </div>
      <!-- ddqend -->
  </div>
</template>

<script>
import { importCDN } from "@/utils/utils";
import { getTodayList, getAds, getDdqList } from '@/services'
import apparel from "../header/images/apparel.png"
import delicacy from "../header/images/delicacy.png"
import makeups from "../header/images/makeups.png"
import dailyUse from "../header/images/dailyUse.png"
import digitalOffice from "../header/images/digitalOffice.png"
import { mapGetters } from "vuex";
var timer = ''
export default {
    computed: {
      ...mapGetters(["marketGroup"]),  
    },
    watch:{
        marketGroup(state){
            // console.log(state)
            this.absList = state
            this.loading = false
            this.$nextTick(() => {
                this.initSwiper()
            })
            
        }
    },
    mounted () {
        importCDN("https://public.ffquan.cn/lib/swiper/js/swiper.min.js").then(
            async () => {
                this.getAdsInfo()
                getDdqList().then(res => {
                    this.ddqLoad = false
                    this.ddqList = res.data.goodsList
                    this.timeDesc = new Date(res.data.ddqTime).getHours()
                    timer = setInterval(() => {
                        var time = new Date()
                        let obj = res.data.roundsList.find(item => item.status == 2) || {}
                        let timeddq = obj.ddqTime
                        var endTime = new Date(timeddq)
                        var h = parseInt((endTime.getTime() - time.getTime()) / 3600000)
                        this.hours = formatNum(h)
                        var m = 60 - endTime.getMinutes() - time.getMinutes() -1
                        var s = 60 - endTime.getSeconds() - time.getSeconds()
                        this.minutes = formatNum(m)
                        this.seconds = formatNum(s)
                        function formatNum(num) {
                            return  num < 10 ? ('0' + num) : num
                        }
                    }, 1000)
                })
                
                this.ddqSwiper = new Swiper(".moveWrap", {
                    direction: "horizontal",
                    slidesPerView: 'auto',
                    slidesPerGroup : 5,
                    observer: true,
                    observerParents: true,
                    // 如果需要前进后退按钮
                    navigation: {
                        nextEl: ".next",
                        prevEl: ".prev",
                    },
                });
                setTimeout(() => {
                    
                    // this.mySwiper.update()
                    this.ddqSwiper.update()
                }, 100);
            }
        );
        this.getTodayInfo()
    },
    data () {
        return {
            mySwiper:false,
            ddqSwiper: false,
            classfilyInfo: [
                {
                    id: 1,
                    name: '服饰鞋包',
                    img: apparel,
                    children: [
                        {
                            lable: '女装',
                            color: 'hot',
                            src: '/?r=l&cids=1&site=classify'
                        },
                        {
                            lable: '男装',
                            src: '/?r=/l&cids=9&site=classify&sort=0'
                        },
                        {
                            lable: '内衣',
                            color: 'hot',
                            src: '/?r=/l&cids=10&site=classify&sort=0'
                        },
                        {
                            lable: '服饰',
                            src: '/?r=/l&cids=12&site=classify&sort=0'
                        },
                        {
                            lable: '鞋品',
                            src: '/?r=/l&cids=5&site=classify&sort=0'
                        },
                        {
                            lable: '箱包',
                            src: '/?r=/l&cids=11&site=classify&sort=0'
                        },
                        {
                            lable: '秋冬穿搭',
                            color: 'hot',
                            src: '/?r=/l&kw=秋冬穿搭&site=classify&sort=0'
                        },
                        {
                            lable: '半身裙',
                            src: '/?r=/l&kw=半身裙&site=classify&sort=0'
                        },
                    ]
                },
                {
                    id: 2,
                    name: '美食',
                    img: delicacy,
                    children: [
                        {
                            lable: '休闲零食',
                            color: 'hot',
                            src: '/?r=/l&kw=休闲零食&site=classify&sort=0'
                        },
                        {
                            lable: '无辣不欢',
                            src: '/?r=/l&kw=无辣不欢&site=classify&sort=0'
                        },
                        {
                            lable: '早餐',
                            src: '/?r=/l&kw=早餐&site=classify&sort=0'
                        },
                        {
                            lable: '生鲜果蔬',
                            src: '/?r=/l&kw=生鲜果蔬&site=classify&sort=0'
                        },
                        {
                            lable: '酒水饮品',
                            color: 'hot',
                            src: '/?r=/l&kw=酒水饮品&site=classify&sort=0'
                        },
                    ]
                },
                {
                    id: 3,
                    name: '美妆',
                    img: makeups,
                    children: [
                        {
                            lable: '隔离防晒',
                            src: '/?r=/l&kw=隔离防晒&site=classify&sort=0'
                        },
                        {
                            lable: '口红',
                            color: 'hot',
                            src: '/?r=/l&kw=口红&site=classify&sort=0'
                        },
                        {
                            lable: '保湿水乳',
                            src: '/?r=/l&kw=保湿水乳&site=classify&sort=0'
                        },
                        {
                            lable: '面膜',
                            src: '/?r=/l&kw=面膜&site=classify&sort=0'
                        },
                        {
                            lable: '美白套装',
                            color: 'hot',
                            src: '/?r=/l&kw=美白套装&site=classify&sort=0'
                        }
                    ]
                },
                {
                    id: 4,
                    name: '居家日用',
                    img: dailyUse,
                    children: [
                        {
                            lable: '居家收纳',
                            color: 'hot',
                            src: '/?r=/l&kw=居家收纳&site=classify&sort=0'
                        },
                        {
                            lable: '洗发护发',
                            src: '/?r=/l&kw=洗发护发&site=classify&sort=0'
                        },
                        {
                            lable: '纸巾',
                            color: 'hot',
                            src: '/?r=/l&kw=纸巾&site=classify&sort=0'
                        },
                        {
                            lable: '防护用品',
                            src: '/?r=/l&kw=防护用品&site=classify&sort=0'
                        },
                        {
                            lable: '家庭清洁',
                            color: 'hot',
                            src: '/?r=/l&kw=家庭清洁&site=classify&sort=0'
                        },
                    ]
                },
                {
                    id: 5,
                    name: '数码办公',
                    img: digitalOffice,
                    children: [
                        {
                            lable: '厨房家电',
                            src: '/?r=/l&kw=厨房家电&site=classify&sort=0'
                        },
                        {
                            lable: '电脑外设',
                            color: 'hot',
                            src: '/?r=/l&kw=电脑外设&site=classify&sort=0'
                        },
                        {
                            lable: '充电宝',
                            src: '/?r=/l&kw=充电宝&site=classify&sort=0'
                        }
                    ]
                },
            ],
            todayList: [],
            showBuyList: [],
            absList: {},
            ddqList: [],
            hours: 0,   //时
            minutes: 0, //分
            seconds: 0, //秒
            timeDesc: '',
            loading: true,
            ddqLoad: true,
            loadingImg: require("./images/indexLoad.png"),
        }
    },
    methods: {
        getTodayInfo: function(){
            getTodayList().then(res => {
                this.todayList = res.data
                this.showBuyList = res.data.slice(0, 3)
            })
        },
        getAdsInfo: async function(){
            this.marketGroup && (this.absList = this.marketGroup)
            this.$nextTick(() => {
                this.initSwiper()
            });
            
            // this.absList = res.data
            // await getAds().then(res => {
            //     this.absList = res.data
            // })
        },
        getRandomBuy: function(arr) {
            return arr.sort((x, y) => {
                    return Math.random() > 0.5 ? -1 : 1;
            }).slice(0, 3)
        },
        handleChangeData: function() {
            this.showBuyList = this.getRandomBuy(this.todayList)
        },
        computeNummber: function(pre, next = 0){
            if(pre === 0 && next === 0 || next === 0){
                return '0%'
            }else{
                return (Number(pre) / Number(next)) * 100 + '%'
            }   
        },
        initSwiper: function() {
            if (!window.Swiper || this.mySwiper) {
                this.mySwiper &&  this.mySwiper.update()
                return
            }
            if(this.mySwiper){
                this.mySwiper.destroy(false);
            }
            if (this.absList.middle_banner) {
                this.loading = false
                setTimeout(()=>{
                    this.mySwiper = new Swiper(".swiperHeader", {
                        // loop: true, // 循环模式选项
                    direction: "horizontal",
                    slidesPerView: 'auto',
                    autoplay:true,
                    loop : true,
                    slidesPerGroup : 1,
                    observer: true,
                    observeSlideChildren:true,
                    // observerParents: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    // 如果需要前进后退按钮
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                });

                },100)
            }

               
        }
    },
    destroyed () {
      
      if(this.mySwiper){
        this.mySwiper.destroy(false);
      } 
      timer = null
      if(this.ddqSwiper)
      this.ddqSwiper.destroy(false) 
    },
}
</script>

<style lang="less" scoped>
@import "https://public.ffquan.cn/lib/swiper/css/swiper.min.css";
.w-1200 {
    width: 1200px;
    min-height: 680px;
    margin: 0 auto;
}
/deep/.swiper-pagination-bullet-active {
    background: #fff;
}
.all_tip_container{
    .all_tip{
    width: 1200px;
    height: 40px;
    background: #FFE5CB;
    border-radius: 20px;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    padding: 14px 23px;
    box-sizing: border-box;
    img{
        width: 21px;
        height: 16px;
        margin-right: 14px;
    }
    span{
        font-size: 14px;
        color: #DD0000;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
    }
    a.isJump{
        font-size: 14px;
        color: #DD0000;
        white-space:nowrap;
        cursor: pointer;
        overflow:hidden;
        text-overflow:ellipsis;
        &:hover{
            color: #B10000;
        }
    }
    }
}

.loadImg {
    // width: 38px !important;
    margin: 0 auto;
    // margin-top: 10px;
    height: 410px;
    width: 670px;
    
    // animation: loading 1s linear 0s infinite;
    img {
        height: 410px;
    width: 670px;
        background-size: 100% 100%;
    }

}
.swiper-button-disabled {
            pointer-events: auto
        }
.ddqLoad {
    margin-top: 125px;
}
@keyframes loading {
0% {
    transform: rotate(0);
}
100% {
    transform: rotate(360deg);
}
}
.containerHead {
    display: flex;
    width: 100%;
    height: 410px;
    margin-bottom: 20px;
    background: #f6f6f6;
    .headLeft {
        width: 220px;
        background: #fff;
        padding: 10px 12px;
        box-sizing: border-box;
        .tit {
            color: #555;
            font-size: 15px;
            line-height: 30px;
            font-weight: 700;
        }
        .ico {
            width: 16px;
            height: 16px;
            position: relative;
            top: 2px;
            left: -5px;
        }
        dd {
            margin: 0 0 10px 15px;
            display: flex;
            flex-wrap: wrap;
            max-height: 44px;
            overflow: hidden;
        }
        dd a {
            color: #888;
            font-size: 14px;
            display: block;
            line-height: 22px;
            margin: 0 8px 0 0;
            &:hover {
                color: #FE2E54;
            }
        }
        .hot {
            color: #FE2E54;
        }
    }
    .bg {
        height: 410px !important;
        background: #fff;
    }
    .headCenter {
        width: 670px;
        height: 300px;
        .swiper {
            height: 300px;
            
        }
        .swiper-container {
            .swiper-button-prev {
                left: 0;
                background: url('./images/pre.png') center;
                background-size: 100% 100%;
                display: none;
            }
            .swiper-button-next {
                right: 0;
                background: url('./images/next.png') center;
                background-size: 100% 100%;
                display: none;
            }
            .swiper-pagination-bullet {
                &.swiper-pagination-bullet-active {
                background: #fff
            }
            }
            
        }
        .swiper-container:hover {
            .swiper-button-prev,.swiper-button-next{
                display: block;
            }
        }
        .swiper-wrapper {
            height: 300px;
            position: relative;
        }
        .swiper-button-disabled {
            opacity: .3 !important;
        }
        .swiper-pagination-bullet-active {
            background: #fff !important;
        }
        .swiper-pagination {
            // text-align: right;
            // padding-right: 20px;
            box-sizing: border-box;
            .swiper-pagination-bullet-active {
                background: #fff !important
            }
        }
        
        .swiperDown {
            display: flex;
            div {
                height: 110px;
                width: 222px;
                margin-left: 1px;
                img{
                    width: 100%;
                    height: 100%;
                    background-size: 100% 100%;
                    border: none;
                }
            }
        }
    }
    .headRight {
        width: 310px;
        height: 410px;
        padding: 20px 14px 18px 18px;
        background-color: #fff;
        box-sizing: border-box;
        .text {
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #333;
            span:nth-child(1){
                font-size: 18px;
                font-weight: 700;
                color: #333;
            }
            span:nth-child(2) {
                font-size: 13px;
                font-weight: 400;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            img {
                width: 15px;
                height: 13px;
                margin-right: 5px;
            }
        }
        .text + p {
            font-size: 12px;
            color: #999;
            line-height: 16px;
            margin-top: 7px;
        }
        .prodWrap {
            position: relative;
            .prodItem {
                width: 100%;
                left: 0;
                top: 0;
                .prodCard:hover {
                    opacity: .85;
                    .cardR .row1 span {
                        color: #ff2049;
                    }
                }
                .prodCard {
                    display: flex;
                    margin-top: 26px;
                    cursor: pointer;
                    .cardL {
                        width: 85px;
                        height: 85px;
                        position: relative;
                        flex-shrink: 0;
                        span {
                            width: 65px;
                            height: 20px;
                            line-height: 20px;
                            position: absolute;
                            left: 0;
                            top: 0;
                            background: #ff2049;
                            background: linear-gradient(90deg,#ff6594 0,#ff2049 100%);
                            border-radius: 0 0 10px;
                            font-size: 12px;
                            color: #fff;
                            text-align: center;
                        }
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    .cardR {
                        flex: 1;
                        margin-left: 8px;
                        overflow: hidden;
                        .row1 {
                            display: flex;
                            align-items: center;
                            i {
                                font-style: normal;
                                height: 14px;
                                width: 14px;
                                background-size: 100% 100%;
                                &.tmail {
                                    background: url('../../../../../berserk/images/tmall.png') center no-repeat;
                                    background-size: contain;
                                }
                                &.maoc {
                                    background: url('../../../../../berserk/images/maoc.png') center no-repeat;
                                    background-size: contain;
                                }
                            }
                            
                            span {
                                flex: 1;
                                font-size: 14px;
                                color: #333;
                                margin-left: 2px;
                                line-height: 20px;
                                width: 160px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }
                        }
                        .row2 {
                            height: 16px;
                            display: flex;
                            align-items: center;
                            margin-top: 9px;
                            span {
                                height: 16px;
                            display: flex;
                            align-items: center;
                            padding: 0 5px;
                            background: #fff7e8;
                            font-size: 12px;
                            color: #ff5900;
                            line-height: 16px;
                                img {
                                    width: 14px;
                                    height: 12px;
                                    margin-right: 4px;
                                    object-fit: contain;
                                }
                            }
                        }
                        .row3 {
                            display: flex;
                            align-items: flex-end;  
                        }
                        .row3>span:nth-of-type(1) {
                            color: #fe2e2e;
                            display: flex;
                            align-items: flex-end;
                            span:first-child{
                                font-size: 14px;
                            }
                            span:last-child{
                                font-size: 21px;
                                line-height: 24px;
                            }
                        }
                        .row3>span:nth-of-type(2) {
                            color: #ababab;
                            font-size: 12px;
                            line-height: 20px;
                            text-decoration: line-through;
                            margin-left: 5px;
                            margin-top: 20px;
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}
.ddQ {
    width: 1200px;
    height: 250px;
    background: #fff;
    display: flex;
    .ddqL {
        width: 180px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: url('./images/dd_bg.png') no-repeat;
        padding-top: 28px;
        box-sizing: border-box;
        .ddqLogo {
            width: 149px;
            height: 27px;
            background: url('./images/dd_logo.png') center no-repeat;
            background-size: 100%;
        }
        .text1 {
            font-size: 16px;
            color: #fff;
            font-weight: 400;
            line-height: 22px;
            margin-top: 10px;
            opacity: .8;
            text-align: center;
        }
        .text2 {
            font-size: 22px;
            color: #fff;
            font-weight: 700;
            line-height: 30px;
            margin-top: 40px;
        }
        .text3 {
            font-size: 13px;
            color: #fff;
            line-height: 18px;
            margin-top: 5px;
            opacity: .85;
        }
        .ddqTime {
            display: flex;
            justify-content: center;
            margin-top: 13px;
            span {
                width: 32px;
                height: 38px;
                text-align: center;
                line-height: 38px;
                background: #3a2a2e;
                color: #fff;
                margin: 0 8px;
                font-size: 20px;
            }
        }
    }
    .ddqR {
        flex: 1;
        overflow: hidden;
        padding: 0 10px;
        position: relative;
        .ddqCardWrap {
            // width: 100%;
            height: 252px;
            overflow: hidden;
            .moveWrap {
                display: flex;
                flex-wrap: nowrap;
                transition: .3s;
                height: 252px;
                .ddCard:hover {
                    opacity: .85;
                    .row1 span {
                        color: #ff2049;
                    }
                }
                .ddCard {
                    width: 200px !important;
                    height: 100%;
                    background-color: #fff;
                    padding: 15px 17px;
                    box-sizing: border-box;
                    cursor: pointer;
                    position: relative;
                    display: inline-block;
                    .lowPrice {
                        height: 18px;
                        position: absolute;
                        left: 50%;
                        top: 133px;
                        transform: translateX(-50%);
                        padding: 0 14px;
                        background: #fff;
                        border-radius: 9px;
                        line-height: 18px;
                        font-size: 13px;
                        color: #ff5f00;
                        white-space: nowrap;
                    }
                    .row1 {
                        height: 20px;
                        display: flex;
                        align-items: center;
                        margin-top: 5px;
                        img {
                            width: 14px;
                            height: 14px;
                            object-fit: contain;
                            flex-shrink: 0;
                        }
                        span {
                            flex: 1;
                            font-size: 14px;
                            color: #333;
                            margin-left: 4px;
                            width: 148px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }
                        i {
                            font-style: normal;
                            height: 14px;
                            width: 14px;
                            background-size: 100% 100%;
                            &.tmail {
                                background: url('../../../../../berserk/images/tmall.png') center no-repeat;
                                background-size: contain;
                            }
                            &.maoc {
                                background: url('../../../../../berserk/images/maoc.png') center no-repeat;
                                background-size: contain;
                            }
                        }
                    }
                    .row2 {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 5px;
                        flex-wrap: nowrap;
                        overflow: hidden;
                        .price1 {
                            display: flex;
                            color: #fe2e2e;
                            align-items: flex-end;
                        }
                        .price2 {
                            color: #ababab;
                            font-size: 12px;
                            line-height: 20px;
                            text-decoration: line-through;
                            margin-left: 5px;
                            display: flex;
                        }
                        .tag {
                            height: 16px;
                            font-size: 12px;
                            color: #ff2828;
                            line-height: 16px;
                            background: #ffe8e7;
                            padding: 0 3px;
                            white-space: nowrap;
                        }
                    }
                    .row3 {
                        width: 166px;
                        height: 20px;
                        background: #ffabaa;
                        border-radius: 10px;
                        margin-top: 8px;
                        position: relative;
                        overflow: hidden;
                        .progress {
                            width: 0;
                            height: 100%;
                            background: #ff3533;
                            border-radius: 10px;
                        }
                    }
                    .row3 > span {
                        font-size: 12px;
                        line-height: 20px;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        color: #fff;
                    }
                }
                .mainImg {
                    width: 140px;
                    height: 140px;
                    object-fit: contain;
                    display: block;
                    margin: 0 auto;
                }
                &:hover {
                    .prev,.next{
                        display: block;
                    }
                }
                .prev {
                    width: 20px;
                    height: 40px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-100%);
                    cursor: pointer;
                    display: none;
                    background: url('./images/pre.png') center no-repeat rgba(0,0,0,.1);
                    border-radius: 0 10px 10px 0;
                    background-size: 100% 100%;
                    opacity: .2;
                    z-index: 10;
                }
                .next {
                    width: 20px;
                    height: 40px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-100%);
                    cursor: pointer;
                    display: none;
                    border-radius: 10px 0 0 10px;
                    right: 0;
                    background: url('./images/next.png') center no-repeat rgba(0,0,0,.1);
                    background-size: 100% 100% !important;
                    opacity: .2;
                    z-index: 10;
                }
            }
        }
    }
}
</style>
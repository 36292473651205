var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dyList.length || _vm.hwList.length
    ? _c(
        "div",
        { staticClass: "adsSpace_container" },
        [
          _vm.dyList.length
            ? _c("goods-space", {
                attrs: {
                  config: _vm.dyConfig,
                  type: "tiktok",
                  data: _vm.dyList
                }
              })
            : _vm._e(),
          _vm.hwList.length
            ? _c("goods-space", {
                attrs: {
                  config: _vm.hwConfig,
                  type: "recommend",
                  data: _vm.hwList
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
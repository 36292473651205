<template>
  <div
    class="goodsSpace_container"
    :style="{ backgroundImage: `url(${config.background})` }"
    :bi-route="type == 'tiktok' ? 'douyin' : 'zhongcao'"
  >
    <div class="goods_list">
      <a
        :href="`?r=l/d&u=1&id=${item.goodsId}&from=dtk`"
        target="_blank"
        :class="{ list_item: true ,isLast:(index + 1) % 3 == 0}"
        v-for="(item, index) in data"
        :key="index"
        @click="handleClick(item)"
        bi-click="goods"
        :data-gid="item.goodsId"
        :data-index="index"
      >
        <img class="mainPic" :src="item.mainPic + '_310x310.jpg'" alt="" />
        <div class="special_text" :title="item.dtitle || item.title">
          <img :src="config.icon" alt="" />
          <span>{{ item.dtitle || item.title }}</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: Object, //背景 icon
    data: Array, // 商品信息
    type: String, // tiktok => 抖音热销 recommend => 好物种草
  },
  methods: {
    handleClick(data) {
      try {
        DTKsatc.eventStat({
          name: this.type == 'tiktok' ? "goodsDyrxClickEvent" : 'goodsZchwClickEvent',
          params: { desc: this.type == 'tiktok' ? "抖音热销模块点击信息" : '种草好物模块点击信息', gid: data.goodsId },
        });
      } catch (err) {}
    },
  },
};
</script>

<style lang="less" scoped>
.goodsSpace_container {
  width: 595px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding: 116px 19px 20px 20px;
  box-sizing: border-box;
  .goods_list {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    .list_item {
      width: 172px;
      position: relative;
      cursor: pointer;
      margin-right: 20px;
      &.isLast {
        margin-right: 0;
      }
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        margin-bottom: 20px;
      }
      img.mainPic {
        width: 172px;
        height: 172px;
      }
      .special_text {
        width: 162px;
        height: 28px;
        background: rgba(0, 0, 0, 0.65);
        border-radius: 14px;
        display: flex;
        align-items: center;
        position: absolute;
        color: #fff;
        bottom: 7px;
        left: 50%;
        transform: translate(-50%);
        img {
          width: 20px;
          height: 20px;
          margin-left: 5px;
          margin-right: 7px;
        }
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          line-height: 1;
        }
      }
    }
  }
}
</style>
<template>
    <div class="containerT">
        <div class="goodsCapition">
            <p>
                <span class="text1">好货精选</span>
                <span class="text2"> 人工精选推荐，每日更新数万款独家优惠</span>
            </p>
            <div class="rightText">
                <img :src="icon_cur" alt="">
                <span>今日共为用户省钱</span>
                <span>￥{{saveMoneyValue}}</span>
            </div>
        </div>
        <div class="goodList" bi-route="haohuo">
            <a bi-click="goods" :data-gid="item.id || item.goodsId" :data-index="index" target="_blank" class="itemCard" v-for="(item, index) in goodsList" :key="item.id"  @click="handleItemClick(item)">
                <!-- <span class="active_tag"></span> -->
                <div v-marketgroup="item">
                    <img v-lazy="`${item.mainPic}_310x310.jpg`" class="main_img"/>
                </div>
                
                <a :href="`?r=l/d&u=1&id=${item.id}&from=dtk`" target="_blank" @click.stop="">
                    <div class="row1">
                        <img :src="getShopImg(item.haitao, item.tchaoshi, item.shopType)" alt="">
                        <span :title="item.dtitle || item.title">{{ item.dtitle || item.title }}</span>
                    </div>
                </a>
                <div class="row2">
                    <span class="tag" v-if="item.brandName">{{ item.brandName }}</span>
                    <span class="tag" v-if="item.couponPrice"
                        >券{{ item.couponPrice }}元</span
                    >
                </div>
                <div class="row3">
                    <span>
                        <span class="curr">￥</span>
                        <span class="price">{{ item.actualPrice }}</span>
                    </span>
                    <span class="sale"> 已售{{ item.monthSales | salesNum(_,1) }}件</span>
                </div>
            </a>
        </div>

        <intersection-oberver-loading @onScroll="handleIntersection" />
        <MicroDetail @onClose="handleMicroDetailClose" v-if="visible" :visible="visible" :id="microDetailId" />
    </div>
</template>

<script>
import { getSaveMoneyInfo, getGoodsList } from '@/services'
import IntersectionOberverLoading from "@/components/IntersectionOberverLoading";
import MicroDetail from "@/components/MicroDetail";
import { mapGetters } from "vuex";
import icon_cur from '@/assets/layout/icon_cur.png'
export default {
    components: {
        IntersectionOberverLoading,
        MicroDetail
    },
    mounted () {
        getSaveMoneyInfo().then(res => {
            if(res.code){
                this.saveMoneyValue = res.data.gmv
            }
        })
        this.getList()
    },
    computed: {
    ...mapGetters(["config"])
    },
    data () {
        return {
            saveMoneyValue: 0,
            goodsList: [],
            pageId: 1,
            visible: false,
            microDetailId: "",
            icon_cur
        }
    },
    methods: {
        getList: function(){
            const item = this.goodsList;
            if(this.pageId == 1 && item.length > 0 || this.pageId > 10 || item.length >= item.totalNum){
                return false;
            }
            let params = {
                pageSize: 20,
                pageId: this.pageId
            }
            getGoodsList(params).then(res => {
                this.goodsList = [...this.goodsList, ...res.data.list]
            })
        },
        getShopImg(haitao, tchaoshi, shopType) {
            if (haitao == 1) return require("../../../../../../assets/search/prod_icon2.png");
            if (tchaoshi == 1)
                return require("../../../../../../assets/search/prod_icon3.png");
            if (shopType == 1)
                return require("../../../../../../assets/search/prod_icon1.png");
            return require("../../../../../../assets/search/prod_icon1.png");
        },
        handleIntersection(){
            this.pageId += 1
            this.getList();
        },
          //点击商品
        handleItemClick(item) {
            if (!this.config.micro_switch) {
                window.open(
                `?r=l/d&u=1&id=${item.id || item.goodsId}&from=dtk`,
                "_blank"
                );
            }else {
                this.visible = true
                this.microDetailId = item.id || item.goodsId
                let dom = document.getElementsByClassName('search-fixed')
                let domTool = document.getElementsByClassName('float-tool')
                dom[0].classList.remove("show");
                domTool[0].style.visibility="hidden";
            }
            
        },
        handleMicroDetailClose() {
            this.visible = false
            this.microDetailId = null
            let dom = document.getElementsByClassName('search-fixed')
            let domTool = document.getElementsByClassName('float-tool')
            dom[0].classList.add("show");
            domTool[0].style.visibility="visible";
        },
    }
}
</script>

<style lang="less" scoped>
.containerT {
    width: 1200px;
    margin: 0 auto;
    .goodsCapition {
        width: 1200px;
        height: 70px;
        box-sizing: border-box;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        background: #fff;
        margin-top: 30px;
        align-items: center;
        p {
            height: 30px;
            .text1 {
                font-size: 24px;
                color: #333;
                font-weight: 700;
            }
            .text2 {
                font-size: 12px;
                color: #999;
            }
        }
        .rightText {
            display: flex;
            align-items: center;
            img {
                width: 21px;
                height: 16px;
                flex-shrink: 0;
            }
            span:nth-of-type(1){
                font-size: 14px;
                color: #333;
                margin-left: 4px;
            }
            span:nth-of-type(2){
                font-size: 17px;
                color: #fe2e54;
                margin-left: 2px;
            }
        }
    }
    .goodList {
        width: 1200px;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
        margin-top: 12px;
        justify-content: space-between;
        .itemCard:hover {
            box-shadow: 0 0 15px rgba(0,0,0,.1);
            opacity: .85;
        }
        .itemCard:hover .row1 span {
            color: #fe2e54;
        }
        .itemCard {
            display: block;
            width: 230px;
            height: 315px;
            background: #fff;
            flex-shrink: 0;
            margin-bottom: 12px;
            list-style: none;
            box-sizing: border-box;
            line-height: 24px;
            // margin-right: 12px;
            padding: 20px 15px 0;
            position: relative;
            text-decoration: none;
            .active_tag{
                width: 190px;
                height: 35px;
                position: absolute;
                left: 20px;
                top: 176px;
                font-size: 12px;
                color: #fff;
                text-align: right;
                box-sizing: border-box;
                padding: 17px 5px 0 0;
                z-index: 10;
                background: url("https://img.alicdn.com/imgextra/i1/2053469401/O1CN0101EIHm2JJi8jgTZtE_!!2053469401.png") left bottom / 100% no-repeat;
                line-height: 18px;
            }
            img {
                width: 190px;
                height: 190px;
                background: rgb(245, 245, 245);
                display: block;
                margin: 0 auto;
            }
            a{
                text-decoration: none;
            }
            .row1 {
                display: flex;
                overflow: hidden;
                flex-wrap: nowrap;
                align-items: center;
                margin-top: 9px;
                img {
                    width: 14px;
                    height: 14px;
                    flex-shrink: 0;
                }
                span {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin-left: 2px;
                    font-size: 14px;
                    color: #333;
                    line-height: 20px;
                }
            }
            .row2 {
                min-height: 16px;
                display: flex;
                align-items: center;
                margin-top: 9px;
                .tag {
                    height: 16px;
                    background: #FFE8E7;
                    border-radius: 2px;
                    padding: 0 3px;
                    font-size: 12px;
                    color: #FF2828;
                    line-height: 16px;
                    margin-right: 5px;
                }
            }
            .row3 {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                margin-top: 9px;
                .curr {
                    font-size: 18px;
                    color: #fe2e2e;
                }
                .price {
                    font-size: 28px;
                    color: #fe2e2e;
                }
                .sale {
                    display: flex;
                    font-size: 14px;
                    color: #ababab;
                }
            }
        }
    }
}
</style>
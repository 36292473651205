import { render, staticRenderFns } from "./GoodsStuff.vue?vue&type=template&id=85e5dc72&scoped=true&"
import script from "./GoodsStuff.vue?vue&type=script&lang=js&"
export * from "./GoodsStuff.vue?vue&type=script&lang=js&"
import style0 from "./GoodsStuff.vue?vue&type=style&index=0&id=85e5dc72&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85e5dc72",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/k8s_dtk_vue_cms_server_pc_v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('85e5dc72')) {
      api.createRecord('85e5dc72', component.options)
    } else {
      api.reload('85e5dc72', component.options)
    }
    module.hot.accept("./GoodsStuff.vue?vue&type=template&id=85e5dc72&scoped=true&", function () {
      api.rerender('85e5dc72', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/home/temp/tempDefault/components/goodsStuff/GoodsStuff.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "brandSelection", attrs: { "bi-route": "pinpai" } },
    [
      _vm._m(0),
      _c("div", { staticClass: "brandWrap" }, [
        _c(
          "div",
          { staticClass: "brandStore" },
          _vm._l(_vm.brandList, function(item, index) {
            return _c(
              "div",
              {
                key: item.brandId,
                staticClass: "storeItem",
                style:
                  index == _vm.brandIndex
                    ? "background-color: rgb(255, 237, 240)"
                    : "",
                attrs: {
                  "bi-click": "list",
                  "data-index": index,
                  "data-id": item.brandId,
                  "data-name": item.brandName
                },
                on: {
                  click: function($event) {
                    return _vm.handleChangeItem(item, index)
                  }
                }
              },
              [
                _c("div", { staticClass: "itemLeft" }, [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: item.brandLogo + "_310x310.jpg",
                        expression: "`${item.brandLogo}_310x310.jpg`"
                      }
                    ],
                    attrs: { alt: "" }
                  })
                ]),
                _c("div", { staticClass: "itemRight" }, [
                  _c("div", { staticClass: "gz rightItem" }, [
                    _c("img", {
                      attrs: { src: require("./images/zhekou.png"), alt: "" }
                    }),
                    _c("span", [
                      _vm._v(
                        " 关注：" + _vm._s(_vm._f("salesNum")(item.fansNum))
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "xl rightItem" }, [
                    _c("img", {
                      attrs: { src: require("./images/xiaoliang.png"), alt: "" }
                    }),
                    _c("span", [
                      _vm._v(" 销量：" + _vm._s(_vm._f("salesNum")(item.sales)))
                    ])
                  ])
                ])
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          {
            staticClass: "swiper-container storeGoods",
            attrs: { id: "storeSwiper" }
          },
          [
            _c(
              "div",
              { staticClass: "swiper-wrapper" },
              _vm._l(_vm.brandGoodList, function(item, index) {
                return _c(
                  "div",
                  { key: item.id, staticClass: "swiper-slide" },
                  [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "marketgroup",
                            rawName: "v-marketgroup",
                            value: item,
                            expression: "item"
                          }
                        ],
                        attrs: {
                          "bi-click": "goods",
                          "data-index": index,
                          "data-pid": _vm.currBrand,
                          "data-gid": item.goodsId,
                          href: "?r=l/d&u=1&id=" + item.goodsId + "&from=dtk",
                          target: "_blank"
                        }
                      },
                      [
                        _c("span", { staticClass: "activeTag" }),
                        _c("img", {
                          attrs: {
                            src: item.mainPic + "_310x310.jpg",
                            alt: "",
                            height: "190",
                            width: "190"
                          }
                        }),
                        _c("div", { staticClass: "row1" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.getShopImg(
                                item.haitao,
                                item.tchaoshi,
                                item.shopType
                              ),
                              alt: ""
                            }
                          }),
                          _c(
                            "span",
                            { attrs: { title: item.dTitle || item.title } },
                            [_vm._v(_vm._s(item.dTitle || item.title))]
                          )
                        ]),
                        _c("div", { staticClass: "row2" }, [
                          item.activityType === 2
                            ? _c("span", { staticClass: "tag" }, [
                                _vm._v("聚划算")
                              ])
                            : _vm._e(),
                          item.couponPrice
                            ? _c("span", { staticClass: "tag" }, [
                                _vm._v("券" + _vm._s(item.couponPrice) + "元")
                              ])
                            : _vm._e(),
                          item.discount != 10
                            ? _c("span", { staticClass: "tag" }, [
                                _vm._v(_vm._s(item.discount) + "折")
                              ])
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "row3" }, [
                          _c("span", [
                            _c("span", { staticClass: "text1" }, [
                              _vm._v("￥")
                            ]),
                            _c("span", { staticClass: "text2" }, [
                              _vm._v(
                                _vm._s(_vm._f("salesNum")(item.actualPrice))
                              )
                            ])
                          ]),
                          _c("span", { staticClass: "sales" }, [
                            _c("span", [
                              _vm._v(
                                "已售" +
                                  _vm._s(_vm._f("salesNum")(item.monthSales)) +
                                  "件"
                              )
                            ])
                          ])
                        ])
                      ]
                    )
                  ]
                )
              }),
              0
            ),
            _c("div", {
              staticClass: "brandPrev",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            }),
            _c("div", {
              staticClass: "brandNext",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "brandTit" }, [
      _vm._v(" 品牌精选"),
      _c("span", [_vm._v("大牌云集，低价大促")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
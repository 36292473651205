var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.config.pc_template_id
      ? _c(
          "div",
          [_vm.config.pc_template_id == 5 ? _c("temp360") : _c("temp-default")],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
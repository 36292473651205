var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-1200" }, [
    _c("div", { staticClass: "containerHead" }, [
      _c(
        "div",
        { staticClass: "headLeft", attrs: { "bi-route": "fenlei" } },
        _vm._l(_vm.classfilyInfo, function(item) {
          return _c("dl", { key: item.id }, [
            _c("dt", { staticClass: "tit" }, [
              _c("img", {
                staticClass: "ico",
                attrs: { src: item.img, alt: "" }
              }),
              _vm._v(_vm._s(item.name))
            ]),
            _c(
              "dd",
              _vm._l(item.children, function(citem, index) {
                return _c(
                  "a",
                  {
                    key: index,
                    class: citem.color ? "hot" : "",
                    attrs: {
                      "bi-click": "leimu",
                      "data-leimu_pname": item.name,
                      "data-leimu_name": citem.lable,
                      href: citem.src,
                      target: "_blank"
                    }
                  },
                  [_vm._v(_vm._s(citem.lable))]
                )
              }),
              0
            )
          ])
        }),
        0
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "headCenter bg"
        },
        [
          _c("div", { staticClass: "loadImg" }, [
            _c("img", { attrs: { src: _vm.loadingImg, alt: "" } })
          ])
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ],
          staticClass: "headCenter",
          attrs: { "bi-route": "lunbo" }
        },
        [
          _c("div", { staticClass: "swiper" }, [
            _c("div", { staticClass: "swiper-container swiperHeader" }, [
              _c(
                "div",
                { staticClass: "swiper-wrapper" },
                _vm._l(_vm.absList.middle_banner, function(item, index) {
                  return _c(
                    "div",
                    { key: item.c_type + index, staticClass: "swiper-slide" },
                    [
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "jump",
                              rawName: "v-jump",
                              value: item,
                              expression: "item"
                            }
                          ],
                          attrs: {
                            "bi-click": "shang",
                            "data-index": index,
                            "data-title": item.title,
                            target: "_blank"
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: item.img,
                              alt: "",
                              height: "300",
                              width: "670"
                            }
                          })
                        ]
                      )
                    ]
                  )
                }),
                0
              ),
              _c("div", {
                staticClass: "swiper-button-prev",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              }),
              _c("div", {
                staticClass: "swiper-button-next",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              }),
              _c("div", { staticClass: "swiper-pagination" })
            ])
          ]),
          _c(
            "div",
            { staticClass: "swiperDown" },
            _vm._l(_vm.absList.middle_bottom_banner, function(item, index) {
              return _c("div", { key: index }, [
                _c(
                  "a",
                  {
                    attrs: {
                      "bi-click": "xia",
                      "data-title": item.title,
                      "data-index": index,
                      href: item.url,
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: item.img + "_310x310.jpg",
                          expression: "`${item.img}_310x310.jpg`"
                        }
                      ],
                      attrs: { alt: "" }
                    })
                  ]
                )
              ])
            }),
            0
          )
        ]
      ),
      _c("div", { staticClass: "headRight", attrs: { "bi-route": "bimai" } }, [
        _c("div", [
          _c("div", { staticClass: "text" }, [
            _c("span", [_vm._v("今日必买")]),
            _c(
              "span",
              {
                staticClass: "changeData",
                attrs: { "bi-click": "huanyipi" },
                on: { click: _vm.handleChangeData }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("../header/images/change_icon.png"),
                    alt: ""
                  }
                }),
                _vm._v("换一批 ")
              ]
            )
          ]),
          _c("p", [_vm._v("精选买送/历史低价/大牌清仓高性价比爆品")])
        ]),
        _c(
          "div",
          { staticClass: "prodWrap" },
          _vm._l(_vm.showBuyList, function(item, index) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "prodItem",
                attrs: {
                  "bi-click": "goods",
                  "data-index": index,
                  "data-gid": item.id
                }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "prodCard",
                    attrs: {
                      href: "?r=l/d&id=" + item.id + "&u=1",
                      target: "_blank"
                    }
                  },
                  [
                    _c("div", { staticClass: "cardL" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            item.specialType == 2
                              ? "品牌折扣"
                              : item.specialType == 3
                              ? "历史低价"
                              : "限时买送"
                          )
                        )
                      ]),
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: item.mainPic + "_310x310.jpg",
                            expression: "`${item.mainPic}_310x310.jpg`"
                          }
                        ],
                        attrs: { alt: "" }
                      })
                    ]),
                    _c("div", { staticClass: "cardR" }, [
                      _c("div", { staticClass: "row1" }, [
                        item.tchaoshi === 1 || item.shopType === 1
                          ? _c("i", {
                              class: [
                                item.tchaoshi ? "maoc" : "tmail",
                                "tagTit"
                              ],
                              attrs: {
                                title: item.tchaoshi ? "天猫超市" : "天猫"
                              }
                            })
                          : _vm._e(),
                        _c(
                          "span",
                          { attrs: { title: item.dtitle || item.title } },
                          [_vm._v(_vm._s(item.dtitle || item.title))]
                        )
                      ]),
                      _c("div", { staticClass: "row2" }, [
                        item.specialType == 2 || item.specialText.length
                          ? _c("span", [
                              _c("img", {
                                attrs: {
                                  src: require("../header/images/prod_icon4.png"),
                                  alt: ""
                                }
                              }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    item.specialType == 2
                                      ? item.brandName
                                      : item.specialType == 3
                                      ? item.specialText[0]
                                      : item.specialText[0]
                                  )
                                )
                              ])
                            ])
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "row3" }, [
                        _c("span", [
                          _c("span", [_vm._v("￥")]),
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("salesNum")(item.actualPrice)))
                          ])
                        ]),
                        _c("span", [
                          _c("span", [_vm._v("￥")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("salesNum")(item.originalPrice))
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                )
              ]
            )
          }),
          0
        )
      ])
    ]),
    _vm.absList.ad_notice_pc && _vm.absList.ad_notice_pc.length
      ? _c(
          "div",
          { staticClass: "all_tip_container" },
          _vm._l(_vm.absList.ad_notice_pc, function(item, index) {
            return _c(
              "div",
              { key: item.c_type + index, staticClass: "all_tip" },
              [
                _c("img", {
                  attrs: { src: require("./images/tip_icon.png"), alt: "" }
                }),
                item.url != "0"
                  ? _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "jump",
                            rawName: "v-jump",
                            value: item,
                            expression: "item"
                          }
                        ],
                        staticClass: "isJump",
                        attrs: { target: "_blank" }
                      },
                      [_vm._v(_vm._s(item.img))]
                    )
                  : _c("span", { staticClass: "isJump" }, [
                      _vm._v(_vm._s(item.img))
                    ])
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _c("div", { staticClass: "ddQ", attrs: { "bi-route": "dongdong" } }, [
      _c("div", { staticClass: "ddqL" }, [
        _vm._m(0),
        _c("p", { staticClass: "text2" }, [
          _vm._v(_vm._s(_vm.timeDesc) + ":00场")
        ]),
        _c("p", { staticClass: "text3" }, [_vm._v("距离结束")]),
        _c("div", { staticClass: "ddqTime" }, [
          _c("span", [_vm._v(_vm._s(_vm.hours))]),
          _c("span", [_vm._v(_vm._s(_vm.minutes))]),
          _c("span", [_vm._v(_vm._s(_vm.seconds))])
        ])
      ]),
      _c("div", { staticClass: "ddqR" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.ddqLoad,
                expression: "!ddqLoad"
              }
            ],
            staticClass: "ddqCardWrap"
          },
          [
            _c("div", { staticClass: "moveWrap" }, [
              _c(
                "div",
                { staticClass: "swiper-wrapper" },
                _vm._l(_vm.ddqList, function(item, index) {
                  return _c(
                    "a",
                    {
                      key: item.id,
                      staticClass: "ddCard swiper-slide",
                      attrs: {
                        href: "index.php?r=l/d&id=" + item.id + "&u=1",
                        target: "_blank",
                        "bi-click": "goods",
                        "data-index": index,
                        "data-gid": item.id,
                        "data-time": _vm.timeDesc + ":00"
                      }
                    },
                    [
                      item.specialText && item.specialText.length
                        ? _c("span", { staticClass: "lowPrice" }, [
                            _vm._v(_vm._s(item.specialText[0]))
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "marketgroup",
                              rawName: "v-marketgroup",
                              value: item,
                              expression: "item"
                            }
                          ]
                        },
                        [
                          _c("img", {
                            staticClass: "mainImg",
                            attrs: {
                              src: item.mainPic + "_310x310.jpg",
                              alt: ""
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "row1" }, [
                        item.tchaoshi === 1 || item.shopType === 1
                          ? _c("i", {
                              class: [
                                item.tchaoshi ? "maoc" : "tmail",
                                "tagTit"
                              ],
                              attrs: {
                                title: item.tchaoshi ? "天猫超市" : "天猫"
                              }
                            })
                          : _vm._e(),
                        _c(
                          "span",
                          { attrs: { title: item.dtitle || item.title } },
                          [_vm._v(_vm._s(item.dtitle || item.title))]
                        )
                      ]),
                      _c("div", { staticClass: "row2" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "flex-end"
                            }
                          },
                          [
                            _c("span", { staticClass: "price1" }, [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "14px" } },
                                [_vm._v("￥")]
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "21px",
                                    "line-height": "24px"
                                  }
                                },
                                [_vm._v(_vm._s(item.actualPrice))]
                              )
                            ]),
                            _c("span", { staticClass: "price2" }, [
                              _c("span", [_vm._v("￥")]),
                              _c("span", [_vm._v(_vm._s(item.originalPrice))])
                            ])
                          ]
                        ),
                        item.ddqDesc
                          ? _c("span", { staticClass: "tag" }, [
                              _vm._v(" " + _vm._s(item.ddqDesc) + " ")
                            ])
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "row3" }, [
                        _c("div", {
                          staticClass: "progress",
                          style: {
                            width: _vm.computeNummber(
                              item.couponReceiveNum,
                              item.couponTotalNum
                            )
                          }
                        }),
                        _c("span", [
                          _c("span", [_vm._v("已抢")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("salesNum")(item.monthSales, _vm._, 1)
                              )
                            )
                          ]),
                          _c("span", [_vm._v("件")])
                        ])
                      ])
                    ]
                  )
                }),
                0
              ),
              _c("div", {
                staticClass: "prev",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              }),
              _c("div", {
                staticClass: "next",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              })
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        attrs: { href: "/?r=ddq&u=1", target: "_blank", "bi-click": "gengduo" }
      },
      [
        _c("div", { staticClass: "ddqLogo" }),
        _c("p", { staticClass: "text1" }, [_vm._v("严选好货限时秒杀")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="home">
    <div class="act-left" v-if="isShowActLeft && marketGroup.left_banner && marketGroup.left_banner.length > 0">
      <div class="pic" :bi-route="chuchuang">
        <a v-jump="marketGroup.left_banner[0]"  
          bi-click="go"
          :data-title="marketGroup.left_banner[0].title"
          :data-url="marketGroup.left_banner[0].url"
          data-weizhi="left_banner"
         >
          <img :src="marketGroup.left_banner[0].img" />
        </a>
        <span class="close" @click="handleHidePopLeftAct"><icon-font  style="font-size: 24px" type="icon-hide" ></icon-font></span>
      </div>
    </div>
    <div class="act-right" v-if="isShowActRight && marketGroup.right_banner && marketGroup.right_banner.length > 0">
      <div class="pic" :bi-route="chuchuang">
        <a v-jump="marketGroup.right_banner[0]"
          bi-click="go"
          :data-title="marketGroup.right_banner[0].title"
          :data-url="marketGroup.right_banner[0].url"
          data-weizhi="right_banner"
        >
          <img :src="marketGroup.right_banner[0].img" />
        </a>
        <span class="close" @click="handleHidePopRightAct"><icon-font type="icon-hide" style="font-size: 24px" ></icon-font></span>
      </div>
    </div>

    <Header/>
    <!-- 榜单 -->
    <PlaceList/>
    <!-- 品牌精选 -->
    <BrandSelection/>
    <!-- 好物种草 抖音热销 -->
    <AdsSpace />
    <!-- 好货精选 -->
    <GoodsStuff/>
    
  </div>
</template>

<script> 
import { Header, PlaceList, BrandSelection, GoodsStuff,AdsSpace } from './components';

import { mapGetters } from "vuex";
import IconFont from "@/components/IconFont.vue";
import { GetCookie, SetCookie } from "@/utils/utils";

export default {
  data() {
    return {
      isShowActRight:!GetCookie('CMS-ACT-RIGHT'),
      isShowActLeft:!GetCookie('CMS-ACT-LEFT'),
    };
  },
  components: {
    Header,
    PlaceList,
    BrandSelection,
    GoodsStuff,
    AdsSpace,
    IconFont,
  },

  computed: {
    ...mapGetters(["marketGroup"])
  },
  methods:{
    handleHidePopLeftAct(){
      SetCookie('CMS-ACT-LEFT',1,24);
      this.isShowActLeft = false;
    },
    handleHidePopRightAct(){
      SetCookie('CMS-ACT-RIGHT',1,24);
      this.isShowActRight = false;
    },
  },
  mounted() {

  },
};
</script>


<style scoped lang="less">
  .home{
    position: relative;z-index: 0;
    overflow: hidden;
  }
  .act-left,.act-right{
    position: absolute;
    right:50%; top: 0;
    margin-right: 600px;

    .pic{
      position: relative; z-index: 0;
      // img{}
      .close{
        display: none;
        position: absolute; color: #fff; top: 5px; right: 5px; z-index: 0; cursor: pointer;
        i{ font-size: 16px; color: #999; 
          &:hover{
            color: #fe2e54;
          }
        }
      }
    }
    &:hover{
      .pic{
        .close{ display: block;}
      }
    }
  }
  .act-right{
    left: 50%; margin: 0 0 0 600px; right: auto;
    .close{
      left:5px; right:auto;
    }
  }
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _vm.isShowActLeft &&
      _vm.marketGroup.left_banner &&
      _vm.marketGroup.left_banner.length > 0
        ? _c("div", { staticClass: "act-left" }, [
            _c(
              "div",
              { staticClass: "pic", attrs: { "bi-route": _vm.chuchuang } },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "jump",
                        rawName: "v-jump",
                        value: _vm.marketGroup.left_banner[0],
                        expression: "marketGroup.left_banner[0]"
                      }
                    ],
                    attrs: {
                      "bi-click": "go",
                      "data-title": _vm.marketGroup.left_banner[0].title,
                      "data-url": _vm.marketGroup.left_banner[0].url,
                      "data-weizhi": "left_banner"
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.marketGroup.left_banner[0].img }
                    })
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "close",
                    on: { click: _vm.handleHidePopLeftAct }
                  },
                  [
                    _c("icon-font", {
                      staticStyle: { "font-size": "24px" },
                      attrs: { type: "icon-hide" }
                    })
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _vm.isShowActRight &&
      _vm.marketGroup.right_banner &&
      _vm.marketGroup.right_banner.length > 0
        ? _c("div", { staticClass: "act-right" }, [
            _c(
              "div",
              { staticClass: "pic", attrs: { "bi-route": _vm.chuchuang } },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "jump",
                        rawName: "v-jump",
                        value: _vm.marketGroup.right_banner[0],
                        expression: "marketGroup.right_banner[0]"
                      }
                    ],
                    attrs: {
                      "bi-click": "go",
                      "data-title": _vm.marketGroup.right_banner[0].title,
                      "data-url": _vm.marketGroup.right_banner[0].url,
                      "data-weizhi": "right_banner"
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.marketGroup.right_banner[0].img }
                    })
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "close",
                    on: { click: _vm.handleHidePopRightAct }
                  },
                  [
                    _c("icon-font", {
                      staticStyle: { "font-size": "24px" },
                      attrs: { type: "icon-hide" }
                    })
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _c("Header"),
      _c("PlaceList"),
      _c("BrandSelection"),
      _c("AdsSpace"),
      _c("GoodsStuff")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
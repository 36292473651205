<template>
  <div class="placeList">
      <!-- 热销榜 -->
      <div class="selectItem" bi-route="bang">
          <a class="selectTitle" href="?r=p" target="_blank" bi-click="gengduo">
              热销榜 <img src="./images/more@2x.png" alt="更多" height="20" width="20">
          </a>
          <h5 class="selectSubTitle">汇总当下热销爆品</h5>
          <div class="selectItemTabs">
              <div>
                  <span bi-click="fenlei" v-for="(item, index) in hotClassIfyList" :key="item.cid"  :class="index == hotIndex ? 'active' : '' " @mouseover="e => handleChangeHot(item, index, 'hot', e)">{{item.cname}}</span>
              </div>
          </div>
          <div class="hotListProduct" id="hWrap" :style="{width: hList.length * 393  +'px'}">
              <div v-for="(outerItem, i) in hList" :key="i + 'hWrap'" class="outerWrap">
                <div class="swiper-container" :id="'swiperHot' + i" >
                    <div class="swiper-wrapper">
                        <div
                            class="swiper-slide"
                            v-for="(item, index) in (new Array(Math.ceil(outerItem.length / 3)).fill(''))"
                            :key="index + hotIndex"
                        >
                            <div class="swiperItem" bi-click="goods" :data-index="cindex" :data-pid="currHotCid" :data-gid="citem.id || citem.goodsId" v-for="(citem, cindex) in outerItem.slice(index * 3, (index + 1) * 3)" :key="citem.id">
                                <a target="_blank" :href="`?r=/l/d&amp;nav_wrap=p&amp;id=${citem.id || citem.goodsId}`">
                                    <div class="cardLeft" v-marketgroup="citem" size="size">
                                        <label>{{citem.ranking}}</label>
                                        <img  alt=""  :src="citem.mainPic + '_310x310.jpg'">
                                    </div>
                                    <div class="cardRight">
                                        <h5 class="cardTit">
                                            <i v-if="citem.tchaoshi === 1 || citem.shopType === 1" :class="[citem.tchaoshi ? 'maoc' : 'tmail' ,'tagTit']" :title="citem.tchaoshi ? '天猫超市' : '天猫'"></i>
                                            <span class="text-ellipsis" :title="citem.dtitle || citem.title">{{citem.dtitle || citem.title}}</span>
                                        </h5>
                                        <div class="selectFeature">
                                            <span v-if="citem.activityType === 2">聚划算</span>
                                            <span>券{{citem.couponPrice}}元</span>
                                        </div>
                                    
                                        <div class="selectPrice">
                                            <span>
                                                <i>￥</i>{{citem.actualPrice | salesNum}}
                                            </span>
                                            <u>￥{{citem.originalPrice | salesNum}}</u>
                                        </div>
                                        <div class="selectSales">
                                            近2小时热度值
                                            <span>{{citem.twoHoursSales | salesNum}}</span>
                                        </div>
                                    </div>
                                </a>
                            </div>  
                        </div>
                    </div>
                    <!-- 如果需要导航按钮 -->
                    <div :class="['swiper-button-prev']" @click.stop=""></div>
                    <div :class="['swiper-button-next']" @click.stop=""></div>
                    <div :class="['splacePagination']"></div>
                </div>
              </div>
          </div>
      </div>
      <!-- 9.9包邮 -->
      <div class="selectItem" bi-route="9kuai9">
          <a class="selectTitle" href="?r=nine&u=1" target="_blank" bi-click="gengduo">
              9.9包邮 <img src="./images/more@2x.png" alt="更多" height="20" width="20">
          </a>
          <h5 class="selectSubTitle">精选好货1.9起</h5>
          <div class="selectItemTabs">
              <div>
                  <span bi-click="fenlei" v-for="(item, index) in linkList" :key="item.lable"  :class="index == nineIndex ? 'active' : '' " @mouseover="e => handleChangeHot(item, index, 'nine', e)">{{item.lable}}</span>
              </div>
          </div>
          <div class="hotListProduct" id="nWrap" :style="{width: nList.length * 393  +'px'}">
              <div v-for="(outerItem, index) in nList" :key="index + 'nWrap'" class="outerWrap">
                <div class="swiper-container" :id="'swiperNine' + index" >
                    <div class="swiper-wrapper">
                        <div
                            class="swiper-slide"
                            v-for="(item, index) in (new Array(Math.ceil(outerItem.length / 3)).fill(''))"
                            :key="index + hotIndex"
                        >
                            <div class="swiperItem" bi-click="goods" :data-index="cindex" :data-pid="currNineCid" :data-gid="citem.id || citem.goodsId" v-for="(citem,cindex) in outerItem.slice(index * 3, (index + 1) * 3)" :key="citem.id">
                                <a target="_blank" :href="`?r=/l/d&amp;nav_wrap=nine&amp;id=${citem.id || citem.goodsId}`">
                                    <div class="cardLeft" v-marketgroup="citem" size="size">
                                        <img alt="" :src="citem.mainPic + '_310x310.jpg'">
                                    </div>
                                    <div class="cardRight">
                                        <h5 class="cardTit">
                                            <i v-if="citem.tchaoshi === 1 || citem.shopType === 1" :class="[citem.tchaoshi ? 'maoc' : 'tmail' ,'tagTit']" :title="citem.tchaoshi ? '天猫超市' : '天猫'"></i>
                                            <span class="text-ellipsis" :title="citem.dtitle || citem.title">{{citem.dtitle || citem.title}}</span>
                                        </h5>
                                        <div class="selectFeature">
                                            <span v-if="citem.activityType === 2">聚划算</span>
                                            <span>券{{citem.couponPrice}}元</span>
                                        </div>
                                        <div class="selectPrice">
                                            <span>
                                                <i>￥</i>{{citem.actualPrice | salesNum}}
                                            </span>
                                            <u>￥{{citem.originalPrice | salesNum}}</u>
                                        </div>
                                        <div class="selectHot">
                                            爆卖
                                            <span>{{citem.monthSales | salesNum(_,1)}}</span>
                                            件
                                        </div>
                                    </div>
                                </a>
                            </div>  
                        </div>
                    </div>
                    <!-- 如果需要导航按钮 -->
                    <div class="swiper-button-prev" @click.stop=""></div>
                    <div class="swiper-button-next" @click.stop=""></div>
                    <div class="splacePagination"></div>
                </div>
            </div>
          </div>
      </div>
      <!-- 折上折 -->
      <div class="selectItem" bi-route="banjia">
          <a class="selectTitle" href="?r=/realtime/zsz" target="_blank" bi-click="gengduo">
              折上折 <img src="./images/more@2x.png" alt="更多" height="20" width="20">
          </a>
          <h5 class="selectSubTitle">聚划算爆品，领券还能省</h5>
          <div class="selectItemTabs">
              <div>
                  <span bi-click="fenlei" v-for="(item, index) in zszClassIfyList" :key="item.cids"  :class="index == zszIndex ? 'active' : '' " @mouseover="e => handleChangeHot(item, index, 'zsz', e)">{{item.cname}}</span>
              </div>
          </div>
          <div class="hotListProduct" id="zWrap" :style="{width: hList.length * 393  +'px'}">
              <div v-for="(outerItem, i) in zList" :key="i + 'zWrap'" class="outerWrap">
                <div class="swiper-container" :id="'swiperZsz' + i" >

                    <div class="swiper-wrapper">
                        <div
                            class="swiper-slide"
                            v-for="(item, index) in (new Array(Math.ceil(outerItem.length / 3)).fill(''))"
                            :key="index + zszIndex"
                        >
                            <div class="swiperItem" bi-click="goods"   v-for="(citem, cindex) in outerItem.slice(index * 3, (index + 1) * 3)" :key="citem.id">
                                <a target="_blank" :href="`?r=/l/d&amp;nav_wrap=p&amp;id=${citem.id || citem.goodsId}`">
                                    <div class="cardLeft" v-marketgroup="citem" size="size">
                                        <img  alt=""  :src="citem.mainPic + '_310x310.jpg'">
                                    </div>
                                    <div class="cardRight">
                                        <h5 class="cardTit">
                                            <i v-if="citem.tchaoshi === 1 || citem.shopType === 1" :class="[citem.tchaoshi ? 'maoc' : 'tmail' ,'tagTit']" :title="citem.tchaoshi ? '天猫超市' : '天猫'"></i>
                                            <span class="text-ellipsis" :title="citem.dtitle || citem.title">{{citem.dtitle || citem.title}}</span>
                                        </h5>
                                        <div class="selectFeature">
                                            <span v-if="citem.activityType === 2">聚划算</span>
                                            <span>券{{citem.couponPrice}}元</span>
                                        </div>
                                        <div class="selectFeature">
                                            <span v-if="citem.specialText && citem.specialText[0]">{{ citem.specialText[0] }}</span>
                                        </div>
                                        <div class="selectPrice">
                                            <span>
                                                <i>￥</i>{{citem.actualPrice | salesNum}}
                                            </span>
                                            <u>￥{{citem.originalPrice | salesNum}}</u>
                                        </div>
                              
                                    </div>
                                </a>
                            </div>  
                        </div>
                    </div>
                    <!-- 如果需要导航按钮 -->
                    <div :class="['swiper-button-prev']" @click.stop=""></div>
                    <div :class="['swiper-button-next']" @click.stop=""></div>
                    <div :class="['splacePagination']"></div>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { importCDN } from "@/utils/utils";
import { getClassify, getRankingList, getNineList, getInfoList } from '@/services'
export default {
    mounted () {
        
        this.getClassifyList()
        importCDN("https://public.ffquan.cn/lib/swiper/js/swiper.min.js").then(
            () => {
                var promise = new Promise((resolve, reject) => {
                    setTimeout(() => {
                        resolve(1)
                    }, 500)
                });
                promise.then( res => {
                    
                    
                })
            }
        );
    },
    data () {
        return {
            swiperHot:[],
            swiperNine: [],
            swiperZsz: [],
            activeIndex: 0,
            hotClassIfyList: [
                {
                    cid: 0,
                    cname: '实时榜',
                    rankType: 1
                },
                {
                    cid: 0,
                    cname: '全天榜',
                    rankType: 2
                }
            ],
            hotList: [], 
            hotIndex: 0,
            nineIndex: 0,
            halfIndex: 0,
            zszIndex:0,
            linkList: [
                {
                    lable: '精选',
                    value: '-1'
                },
                {
                    lable: '5.9元区',
                    value: '1'
                },
                {
                    lable: '9.9元区',
                    value: '2'
                },
                {
                    lable: '19.9元区',
                    value: '3'
                },
            ],
            zszClassIfyList: [
                {
                    cids: -1,
                    cname: '精选好货',
                },
                {
                    cids: 6,
                    cname: '爆款美食',
                },
                {
                    cids: 2,
                    cname: '人气母婴',
                },
                {
                    cids: 4,
                    cname: '居家生活',
                },
            ],
            timerHalfList: [],
            hList: [[],[],[],[],[],[]],
            nList: [[],[],[],[]],
            dList: [],
            zList:[[],[],[],[]]
        }
    },
    computed: {
        currHotCid() {
            let obj = this.hotClassIfyList.find((i, index) => index == this.hotIndex) || {}
            return obj.cid || obj.cname
        },
        currNineCid() {
            let obj = this.linkList.find((i, index) => index == this.nineIndex) || {}
            return obj.value || obj.lable
        },
        currHalfCid() {
            let obj = this.timerHalfList.find((i, index) => index == this.halfIndex) || {}
            return `${obj.hpdTime}:00` || null
        },
    },
    methods: {
        getClassifyList: function() {
            getClassify().then(res => {
                this.hotClassIfyList = [...this.hotClassIfyList, ...res.data.slice(0, 4)]
                let params = {
                    rankType: 1,
                    cid: 0,
                }
                this.getHotLList(params, 0)
            })
            this.getNineList({nineCid: -1}, 0)
            this.getZszLList({}, 0)
        },
        initSwiper(dom, index){
            if(!window.Swiper) return
            this[dom][index] = new Swiper('#' + dom + index, {
                    // loop: true, // 循环模式选项
                    // slidesPerView: 'auto',
                    slidesPerGroup : 1,
                    initialSlide: 0,
                    lazyLoading: true,
                    observer: true,
                    observerParents: true,
                    pagination: {
                        el: ` .splacePagination`,
                        clickable: true
                    },
                    // 如果需要前进后退按钮
                    navigation: {
                        nextEl: `.swiper-button-next`,
                        prevEl: `.swiper-button-prev`,
                    }
            });
            setTimeout(() => {
                this[dom][index].update()
            }, 100);
        },
        getHotLList: function(params, index) {
            if(this.hList && this.hList[index].length > 0){
                return false;
            }
            getRankingList(params).then(res => {
                this.hotList = res.data || []
                this.$nextTick(() => {
                    this.hList = this.hList.map((r,key)=>(
                        key == index ? [...r,...res.data] : r
                    ))
                    setTimeout(() => {
                        this.initSwiper('swiperHot', index)
                    }, 500);
                })
            })
        },
        getNineList: function(params, index) {
            if(this.nList && this.nList[index].length > 0){
                return false;
            }
            getNineList(params).then(res => {
                this.$nextTick(() => {
                    this.nList = this.nList.map((r, key) => (
                        key == index ? [...r, ...res.data.list] : r
                    ))
                    setTimeout(() => {
                        this.initSwiper('swiperNine', index)
                    }, 500);
                })
                
            })
        },
        getZszLList: function(params, index) {
            if(this.zList && this.zList[index].length > 0){
                return false;
            }

            getInfoList(params).then(res => {
                this.$nextTick(() => {
                    this.zList = this.zList.map((r,key)=>(
                        key == index ? [...r,...res.data.list] : r
                    ))
                    setTimeout(() => {
                        this.initSwiper('swiperZsz', index)
                    }, 500);
                })

            })
        },
        getHalfList: function(params, index) {
            getInfoList(params).then(res => {
                if (res && res.code !== 0) {
                    return
                }
                let tList = res.data.list || [];
                this.timerHalfList = tList.filter(item => item.status != 0)
                
                if(this.dList.length == 0) {
                    this.dList.length = this.timerHalfList.length
                    this.dList.fill([])
                }else if(this.dList && this.dList[index].length > 0){
                    return
                }
                this.dList = this.dList.map((r, key) => {
                        return key == index ? [...r, ...res.data.halfPriceInfo.list].slice(0, 9) : r
                    })
                this.$nextTick(() => {
                    
                    this.initSwiper('swiperHalf', index)
                })
                
            })
        },
        handleChangeHot: function(item, index, type, e) {
            if(type === 'hot'){
                
                if(index == this.hotIndex) return 
                try {
                    this.$BIJsdk.onEvent({
                        event:e.target,
                        extra:{
                            index: index,
                            id: item.cid || undefined,
                            name: item.cname
                        },
                        event_type:'mouseover',
                    }) 
                } catch (error) {
                    
                }
                let params = {
                    rankType: item.rankType || 1,
                    cid: item.cid,
                }
                this.getHotLList(params, index)
                
                let dom = document.getElementById('hWrap')
                dom.style.transform = `translate3d(-${(index) * 393}px, 0, 0)`
                this.hotIndex = index
            }else if(type === 'nine'){
                if(index == this.nineIndex) return 

                 try {
                    this.$BIJsdk.onEvent({
                        event:e.target,
                        extra:{
                            index: index,
                            id: item.value || undefined,
                            name: item.lable
                        },
                        event_type:'mouseover',
                    }) 
                } catch (error) {
                    
                }
                this.nineIndex = index
                this.getNineList({nineCid: item.value}, index)
                let dom = document.getElementById('nWrap')
                dom.style.transform = `translate3d(-${(index) * 393}px, 0, 0)`
            }else {
                // if(index == this.halfIndex) return 

                //  try {
                //     this.$BIJsdk.onEvent({
                //         event:e.target,
                //         extra:{
                //             index: index,
                //             name: `${item.hpdTime}:00`
                //         },
                //         event_type:'mouseover',
                //     }) 
                // } catch (error) {
                    
                // }
                // this.halfIndex = index
                // this.getHalfList({sessions: item.hpdTime}, index)
                // let dom = document.getElementById('dWrap')
                // dom.style.transform = `translate3d(-${(index) * 393}px, 0, 0)`


                if(index == this.zszIndex) return 
                try {
                    this.$BIJsdk.onEvent({
                        event:e.target,
                        extra:{
                            index: index,
                            id: item.cids || undefined,
                            name: item.cname
                        },
                        event_type:'mouseover',
                    }) 
                } catch (error) {
                    
                }
                let params = {
                    cids: item.cids,
                    sort:2
                }
                this.getZszLList(params, index)
                
                let dom = document.getElementById('zWrap')
                dom.style.transform = `translate3d(-${(index) * 393}px, 0, 0)`
                this.zszIndex = index

            }
        },
        destroySwiper(arr, dom){
            for(let i in arr){
                this[dom][i].destroy(false) 
            }
        }
    },
    destroyed () {
        this.destroySwiper(this.swiperHot, 'swiperHot')
        this.destroySwiper(this.swiperNine, 'swiperNine')
        this.destroySwiper(this.swiperHalf, 'swiperHalf')
    },
}
</script>

<style lang="less" scoped>
.placeList {
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    .selectItem {
        width: 393px;
        height: 546px;
        background: #fff;
        padding-top: 20px;
        box-sizing: border-box;
        overflow: hidden;
        .selectTitle {
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            height: 31px;
            font-size: 24px;
            font-weight: 700;
            color: #333;
            line-height: 31px;
            margin: 0;
            padding: 0 20px;
            display: inline-block;
        }
        .selectSubTitle {
            height: 17px;
            font-size: 13px;
            color: #888;
            line-height: 17px;
            font-weight: 400;
            margin: 7px 0 20px;
            padding: 0 20px;
        }
        .selectItemTabs {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            width: 353px;
            height: 20px;
            margin: 0 10px;
            overflow: hidden;
            box-sizing: border-box;
            div {
                height: 20px;
                line-height: 20px;
                white-space: nowrap;
                transition: all .35s ease-in-out;
                margin: 0;
                padding: 0;
                span {
                    list-style: none;
                    margin: 0 10px;
                    height: 20px;
                    font-size: 15px;
                    color: #333;
                    line-height: 20px;
                    position: relative;
                    cursor: pointer;
                    &.active {
                        color: #fe2e54;
                    }
                }
            }
        }
        .hotListProduct {
            width: 393px;
            overflow: hidden;
            display: flex;
            transition: all .35s ease-in-out;
            .outerWrap {
                width: 393px;
                
            }
        }
    }
    .selectItem:first-child {
        background: url('./images/section01@2x.png') 245px 13px no-repeat #fff;
        background-size: 135px 135px;
    }
    .selectItem:nth-child(2) {
        background: url('./images/section02@2x.png') 245px 13px no-repeat #fff;
        background-size: 135px 135px;
    }
    .selectItem:nth-child(3) {
        background: url('./images/section03@2x.png') 245px 13px no-repeat #fff;
        background-size: 135px 135px;
    }
    .swiper-slide {
        height: 400px;
        width: 393px;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        background: #fff;
        cursor: pointer;
        .slideCard {
             display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            width: 393px;
            height: 106px;
            margin-top: 24px;
            padding: 0 20px;
            box-sizing: border-box;
            text-decoration: none;
            list-style: none;
        }
    }
    .swiperItem:hover {
        opacity: .85;
        .text-ellipsis{
            color: #FF2828;
        }
    }
    .swiperItem {
        a {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            width: 393px;
            height: 106px;
            margin-top: 24px;
            padding: 0 20px;
            box-sizing: border-box;
            text-decoration: none;
            .cardLeft {
                width: 106px;
                height: 106px;
                overflow: hidden;
                position: relative;
                label {
                    background: url('./images/label@2x.png') top center no-repeat;
                    background-size: 28px 32px;
                    position: absolute;
                    width: 28px;
                    height: 32px;
                    line-height: 30px;
                    text-align: center;
                    box-sizing: border-box;
                    left: 0;
                    top: 0;
                    font-size: 16px;
                    font-family: Arial-BoldMT,Arial;
                    font-weight: 400;
                    color: #fff;
                }
                img {
                    width: 106px;
                    height: 106px;
                }
            }
            .cardRight {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                width: 247px;
                height: 106px;
                box-sizing: border-box;
                padding-left: 18px;
                align-items: flex-start;
                h5 {
                    margin: 0;
                    padding: 0;
                    width: 229px;
                    height: 17px;
                    font-size: 13px;
                    color: #333;
                    line-height: 17px;
                    
                    
                }
                .cardTit {
                    span {
                        display: block;
                        width: 213px;
                        padding-left: 2px;
                        // float: right;
                    }
                    i {
                        display: block;
                        float: left;
                        font-style: normal;
                        height: 14px;
                        width: 14px;
                        background-size: 100% 100%;
                        &.tmail {
                            height: 14px;
                            width: 14px;
                            background: url('../../../../../berserk/images/tmall.png') center no-repeat;
                            background-size: contain;
                        }
                        &.maoc {
                            height: 14px;
                            width: 14px;
                            background: url('../../../../../berserk/images/maoc.png') center no-repeat;
                            background-size: contain;
                        }
                    }
                }
                .selectFeature {
                    height: 16px;
                    margin: 10px 0 6px;
                    flex-wrap: wrap;
                    span {
                        padding: 0 3px;
                        height: 16px;
                        background: #ffe8e7;
                        border-radius: 2px;
                        font-size: 12px;
                        font-weight: 400;
                        margin: 0 4px 0 0;
                        color: #ff2828;
                    }
                    span:first-child {
                        margin: 0 4px 0 0;
                    }
                }
                .selectPrice {
                    height: 23px;
                    align-items: baseline;
                    span {
                        font-size: 21px;
                        color: #fe2e2e;
                        i {
                            width: 8px;
                            font-size: 14px;
                            color: #fe2e2e;
                            font-style: normal;
                        }
                    }
                    u {
                        font-size: 12px;
                        color: #ababab;
                        text-decoration: line-through;
                        // padding-left: 4px;
                    }
                }
                .selectSales {
                    width: 165px;
                    height: 21px;
                    line-height: 21px;
                    text-align: center;
                    margin-top: 5px;
                    background: #ffeee6;
                    border-radius: 0 11px 11px;
                    font-size: 13px;
                    font-weight: 400;
                    color: #be4603;
                    span {
                        color: #ff5b00;
                        padding: 0 1px;
                    }
                }
                .selectHot {
                    height: 20px;
                    margin-top: 6px;
                    background: url('./images/hot@2x.png') 0 2px no-repeat;
                    background-size: 13px 15px;
                    padding-left: 18px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #777;
                    line-height: 20px;
                    span {
                        color: #ff5b57;
                        // padding: 0 3px;
                    }
                }
            }
        }
    }
    .swiper-container:hover {
        .swiper-button-prev, .ninePrev, .halfPrev,.swiper-button-next, .nineNext, .halfNext {
            display: block;
            opacity: .5;
        }
        .swiper-button-disabled {
            pointer-events: auto
        }
    }
    .splacePagination, .ninePagination, .halfPagination {
        text-align: center;
        /deep/.swiper-pagination-bullet-active {
            background: #fe2e54;
        }
    }

    .swiper-button-prev, .ninePrev, .halfPrev {
        width: 15px;
        height: 40px;
        // background: rgba(0,0,0,.2);
        background: url('../header/images/pre.png') center no-repeat;
        background-size: 100% 100%;
        border-radius: 0 10px 10px 0;
        justify-content: center;
        position: absolute;
        margin-top: -20px;
        display: none;
        cursor: pointer;
        z-index: 11;
        top: 50%;
        left: 0;
    }
    .swiper-button-next, .nineNext, .halfNext {
        width: 15px;
        height: 40px;
        // background: rgba(0,0,0,.2);
        background: url('../header/images/next.png') center no-repeat;
        background-size: 100% 100%;
        border-radius: 0 10px 10px 0;
        justify-content: center;
        position: absolute;
        margin-top: -20px;
        display: none;
        cursor: pointer;
        z-index: 11;
        top: 50%;
        right: 0;
    }
}
.text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
    .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
        background: #fe2e54;
    }
.swiper-button-disabled {
            pointer-events: auto
        }
</style>
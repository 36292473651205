<template>
  <div>
    <!-- <div
      v-html="
        `
      <style>
      html {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
  -webkit-filter: grayscale(1);
}
      </style>
    `
      "
    ></div> -->
    <div v-if="config.pc_template_id">
      <temp360 v-if="config.pc_template_id == 5"></temp360>
      <temp-default v-else></temp-default>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GetCookie, SetCookie } from "@/utils/utils";
import tempDefault from "./temp/tempDefault";

export default {
  data() {
    return {};
  },
  components: {
    "temp-default": tempDefault,
    temp360: () => import("./temp/temp360"),
  },

  computed: {
    ...mapGetters(["config"]),
  },
  methods: {},
  mounted() {
    console.log(this.config.pc_template_id);
  },
};
</script>

<style scoped lang="less"></style>

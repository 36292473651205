var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "goodsSpace_container",
      style: { backgroundImage: "url(" + _vm.config.background + ")" },
      attrs: { "bi-route": _vm.type == "tiktok" ? "douyin" : "zhongcao" }
    },
    [
      _c(
        "div",
        { staticClass: "goods_list" },
        _vm._l(_vm.data, function(item, index) {
          return _c(
            "a",
            {
              key: index,
              class: { list_item: true, isLast: (index + 1) % 3 == 0 },
              attrs: {
                href: "?r=l/d&u=1&id=" + item.goodsId + "&from=dtk",
                target: "_blank",
                "bi-click": "goods",
                "data-gid": item.goodsId,
                "data-index": index
              },
              on: {
                click: function($event) {
                  return _vm.handleClick(item)
                }
              }
            },
            [
              _c("img", {
                staticClass: "mainPic",
                attrs: { src: item.mainPic + "_310x310.jpg", alt: "" }
              }),
              _c(
                "div",
                {
                  staticClass: "special_text",
                  attrs: { title: item.dtitle || item.title }
                },
                [
                  _c("img", { attrs: { src: _vm.config.icon, alt: "" } }),
                  _c("span", [_vm._v(_vm._s(item.dtitle || item.title))])
                ]
              )
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="brandSelection" bi-route="pinpai">
        <h3 class="brandTit">
            品牌精选<span>大牌云集，低价大促</span>
        </h3>
        <div class="brandWrap">
            <div class="brandStore">
                <div class="storeItem" bi-click="list" :data-index="index" :data-id="item.brandId" :data-name="item.brandName" v-for="(item, index) in brandList" :key="item.brandId" :style="index == brandIndex ? 'background-color: rgb(255, 237, 240)' : ''" @click="handleChangeItem(item, index)">
                    <div class="itemLeft">
                        <img v-lazy="`${item.brandLogo}_310x310.jpg`" alt="">
                    </div>
                    <div class="itemRight">
                        <div class="gz rightItem">
                            <img src="./images/zhekou.png" alt="">
                            <span> 关注：{{item.fansNum | salesNum}}</span>
                        </div>
                        <div class="xl rightItem">
                            <img src="./images/xiaoliang.png" alt="">
                            <span> 销量：{{item.sales | salesNum}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-container storeGoods" id="storeSwiper">
                <div class="swiper-wrapper">
                    <div
                        class="swiper-slide"
                        v-for="(item, index) in brandGoodList"
                        :key="item.id"
                    >
                        <a bi-click="goods" :data-index="index" :data-pid="currBrand" :data-gid="item.goodsId" :href="`?r=l/d&u=1&id=${item.goodsId}&from=dtk`" target="_blank" v-marketgroup="item">
                            <span class="activeTag"></span>
                            <img :src="item.mainPic + '_310x310.jpg'" alt="" height="190" width="190">
                            <div class="row1">
                                <!-- <i v-if="item.tchaoshi === 1 || item.shopType === 1" :class="[item.tchaoshi ? 'maoc' : 'tmail' ,'tagTit']" :title="item.tchaoshi ? '天猫超市' : '天猫'"></i> -->
                                <img :src="getShopImg(item.haitao, item.tchaoshi, item.shopType)" alt="">
                                <span :title="item.dTitle || item.title">{{item.dTitle || item.title}}</span>
                            </div>
                            <div class="row2">
                              <span class="tag" v-if="item.activityType === 2">聚划算</span>
                                <span class="tag" v-if="item.couponPrice">券{{item.couponPrice}}元</span>
                                <span class="tag" v-if="item.discount != 10">{{item.discount}}折</span>
                            </div>
                            <div class="row3">
                                <span>
                                    <span class="text1">￥</span>
                                    <span class="text2">{{item.actualPrice | salesNum}}</span>
                                </span>
                                <span class="sales">
                                    <span>已售{{item.monthSales | salesNum}}件</span>
                                </span>
                            </div>
                        </a>
                    </div>
                </div>
                <!-- 如果需要导航按钮 -->
                <div class="brandPrev" @click.stop=""></div>
                <div class="brandNext" @click.stop=""></div>
            </div>
        </div>
    </div>
</template>

<script>
import { importCDN } from "@/utils/utils";
import { getBrandList } from '@/services'
export default {
    mounted () {
        this.getBrandInfo()
        importCDN("https://public.ffquan.cn/lib/swiper/js/swiper.min.js").then(
            () => {
                this.brandSwiper = new Swiper("#storeSwiper", {
                    // loop: true, // 循环模式选项
                    direction: "horizontal",
                    slidesPerGroup : 4,
                    slidesPerView: 'auto',
                    lazyLoading: true,
                    observer: true,
                    observerParents: true,
                    // 如果需要前进后退按钮
                    navigation: {
                        nextEl: ".brandNext",
                        prevEl: ".brandPrev",
                    },
                });
                this.brandSwiper.slideTo(0)
                setTimeout(() => {
                    this.brandSwiper.update()
                }, 100);
            }
        );
        // setTimeout(() => {
        //     this.brandSwiper.slideTo(0)
        // }, 2000);
    },
    data () {
        return {
            brandSwiper: false,
            brandList: [],
            brandIndex: 0,
            brandGoodList: []
        }
    },
    computed: {
        currBrand() {
            let obj = this.brandList.find((i, index) => index == this.brandIndex) || {}
            return obj.brandId || null
        }
    },
    methods: {
        getBrandInfo: function() {
            getBrandList().then(res => {
                this.brandList = res.data.list.slice(0, 3) || []
                this.brandGoodList = this.brandList[0].list
            })
        },
        handleChangeItem: function(item, index) {
            if(index == this.brandIndex) return
            this.brandSwiper.slideTo(0)
            this.brandIndex = index
            this.brandGoodList = item.list
        },
        getShopImg(haitao, tchaoshi, shopType) {
            if (haitao == 1) return require("../../../../../../assets/search/prod_icon2.png");
            if (tchaoshi == 1)
                return require("../../../../../../assets/search/prod_icon3.png");
            if (shopType == 1)
                return require("../../../../../../assets/search/prod_icon1.png");
            return require("../../../../../../assets/search/prod_icon1.png");
        },
    },
    destroyed () {
      this.brandSwiper.destroy(false)  
    },
}
</script>

<style lang="less" scoped>
.brandSelection {
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    box-sizing: border-box;
    padding: 20px 15px 20px 20px;
    background: #fff;
    align-items: flex-start;
    height: 396px;
    background: url('./images/brand_bg.png') no-repeat;
    background-size: 100% 100%; 
    .brandTit {
        height: 31px;
        font-size: 24px;
        font-weight: 700;
        color: #333;
        line-height: 31px;
        align-items: baseline;
        margin: 0;
        padding: 0;
        width: 1165px;
        span {
            height: 17px;
            font-size: 13px;
            color: #888;
            line-height: 17px;
            padding-left: 10px;
        }
    }
    .brandWrap {
        width: 1165px;
        margin-top: 20px;
        display: flex;
        .brandStore {
            width: 220px;
            background-position: top center;
            background-repeat: no-repeat;
            background-size: cover;
            align-items: flex-start;
            justify-content: space-between;
            .storeItem {
                width: 100%;
                height: 103px;
                display: flex;
                background: #fff;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                font-size: 12px;
                .itemRight {
                    margin-left: 12px;
                    width: 120px;
                    img {
                        height: 14px;
                        width: 12px;
                    }
                    .rightItem {
                        display: flex;
                        align-items: center;
                        padding-left: 9px;
                        padding-right: 9px;
                        white-space: nowrap;
                        span {
                            line-height: 1;
                            margin-left: 3px;
                        }
                    }
                    .gz {
                        color: #fff;
                        height: 22px;
                        background-image: linear-gradient(90deg,#FF6594 0,#FF2049 100%);
                        border-radius: 11px;
                        margin-bottom: 12px;
                    }
                    .xl {
                        height: 22px;
                        background-color: #FFEDF0;
                        border-radius: 11px;
                        color: #FF0332;
                    }
                }
                .itemLeft {
                    height: 55px;
                    display: flex;
                    align-items: center;
                    border-radius: 50%;
                    max-width: 75px;
                    img {
                        width: 100%;
                        border-radius: 50%;
                    }
                }
            }
        }
        .storeGoods {
            box-sizing: border-box;
            overflow: hidden;
            position: relative;
            margin-left: 30px;
            flex: 1;
            .swiper-wrapper{
                background: #fff;
            }
            .swiper-slide {
                padding: 20px 15px 0;
                position: relative;
                text-decoration: none;
                width: 230px;
                height: 315px;
                background: #fff;
                flex-shrink: 0;
                margin-bottom: 12px;
                list-style: none;
                box-sizing: border-box;
                line-height: 24px;
            }
            .swiper-slide:hover {
                opacity: .85;
                .row1 span{
                    color: #FF2828;
                }
            }
            .brandPrev {
                left: 0;
                background: url('../header/images/pre.png');
                background-size: 100% 100%;
            }
            .brandNext {
                right: 0;
                background: url('../header/images/pre.png');
                background-size: 100% 100%;
                transform: rotate(180deg);
            }
            .brandPrev, .brandNext{
                width: 15px;
                height: 40px;
                border-radius: 0 10px 10px 0;
                justify-content: center;
                position: absolute;
                margin-top: -20px;
                cursor: pointer;
                z-index: 11;
                top: 50%;
                display: none;
                opacity: .6;
            }
            &:hover {
                .brandPrev, .brandNext{
                    display: block;
                }
            }
        }
        .row1 {
            display: flex;
            overflow: hidden;
            flex-wrap: nowrap;
            align-items: center;
            margin-top: 9px;
            img {
                width: 14px;
                height: 14px;
                flex-shrink: 0;
            }
            span {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                margin-left: 2px;
                font-size: 14px;
                color: #333;
                line-height: 20px;
            }
            i {
                display: block;
                float: left;
                font-style: normal;
                height: 14px;
                width: 14px;
                background-size: 100% 100%;
                &.tmail {
                    height: 14px;
                    width: 14px;
                    background: url('../../../../../berserk/images/tmall.png') center no-repeat;
                    background-size: contain;
                }
                &.maoc {
                    height: 14px;
                    width: 14px;
                    background: url('../../../../../berserk/images/maoc.png') center no-repeat;
                    background-size: contain;
                }
            }
        }
        .row2 {
            min-height: 16px;
            display: flex;
            align-items: center;
            margin-top: 9px;
            .tag {
                height: 16px;
                background: #FFE8E7;
                border-radius: 2px;
                padding: 0 3px;
                font-size: 12px;
                color: #FF2828;
                line-height: 16px;
                margin-right: 5px;
            }
        }
        .row3 {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-top: 9px;
            .text1{
                font-size: 18px;
                color: #FE2E2E;
            }
            .text2 {
                font-size: 28px;
                color: #FE2E2E;
            }
            .sales {
                display: flex;
                font-size: 14px;
                color: #ABABAB;
            }
        }
    }
}
</style>